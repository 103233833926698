import { useAuthContext } from '../context/AuthContext';
import { fetcher } from './index';

type Fetcher = <T, B>(
  url: string,
  method?: string,
  body?: B | undefined,
  headers?: HeadersInit
) => Promise<T | { error: string }>;

export const useAuthedFetch = (): Fetcher => {
  const { getAccessToken } = useAuthContext();
  return async <T, B>(
    url: string,
    method = 'get',
    body: B | undefined = undefined,
    headers = {}
  ): Promise<T | { error: string }> => {
    const token = await getAccessToken();
    if (!token) {
      throw Error('Not logged in');
    }
    return fetcher(url, method, body, {
      ...headers,
      Authorization: `Bearer ${token}`,
    });
  };
};
