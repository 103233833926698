import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { ReactElement } from 'react';
import { Button } from '../components/Button';
import { SelectField, InputField } from '../components/Input';
import { TableOptions } from '../components/TableOptions';

export const StylesContainer = (): ReactElement => {
  return (
    <div className="w-full">
      <div className="border-b border-solid pb-2 flex">
        <Button size="small" className="mr-2">
          Small Button
        </Button>
        <Button size="small" colour="secondary">
          Small Button
        </Button>
      </div>
      <div className="flex">
        <Button className="mr-2" size="normal">
          Normal Button
        </Button>
        <Button
          className="mr-2"
          size="normal"
          colour="secondary"
          icon={ArrowUturnLeftIcon}
        >
          Normal Button
        </Button>
        <Button className="mr-2" size="normal" colour="secondary" disabled>
          Normal Button
        </Button>
        <Button className="mr-2" size="normal" colour="primary" disabled>
          Normal Button
        </Button>
        <TableOptions options={[]} />
        <InputField className="ml-2" />
        <SelectField
          className="w-48 ml-2"
          options={['Dog', 'Cat'].map((v) => ({ label: v, value: v }))}
        />
      </div>
    </div>
  );
};
