import { PropsWithChildren, ReactElement } from 'react';

interface TextProps {
  variant: 'h1' | 'h2' | 'h3';
}

export const Text = ({
  children,
  variant,
}: PropsWithChildren<TextProps>): ReactElement => {
  if (variant === 'h1') {
    return (
      <h1 className="text-gray-900 mb-4 font-extrabold text-2xl">{children}</h1>
    );
  }
  if (variant === 'h2') {
    return <h2 className="text-lg text-gray-900">{children}</h2>;
  }
  if (variant === 'h3') {
    return <h3>{children}</h3>;
  }
  return <span>{children}</span>;
};
