import { useState, ReactElement, useCallback } from 'react';
import { Edit2 } from 'react-feather';
import { useAccounts } from '../api/accounts';
import { useModal } from '../hooks/modal';
import { Button } from '../components/Button';
import { Page, PageHeader } from '../components/Page';
import { Table, TwoLineCell } from '../components/Table';
import { AddCategoryModal } from './AddCategoryModal';
import { useBudgets } from '../api/budget';
import { Money } from '../components/Money';
import { Currency, Category, Account } from '../contracts';
import { EditCategoryBudgetModal } from './EditCategoryBudgetModal';
import { categoryIcon } from '../icons';

export const BudgetColumn = ({
  isLoading,
  value,
  current = 0,
  onEdit,
}: {
  isLoading: boolean;
  current?: number;
  value?: number;
  onEdit: () => void;
}): ReactElement => {
  if (isLoading) {
    return <></>;
  }

  if (!value) {
    return <Edit2 height={12} onClick={onEdit} />;
  }

  return (
    <div className="flex flex-row justify-center items-center">
      <Money value={current} currency={Currency.GBP} />
      <span className="pl-2 pr-2">of</span>
      <Money value={value} currency={Currency.GBP} />
      <Edit2 height={12} onClick={onEdit} />
    </div>
  );
};

BudgetColumn.defaultProps = {
  value: undefined,
  current: 0,
};

export const CategorySettingsContainer = (): ReactElement => {
  const categories = useAccounts({ types: ['EXPENSE', 'REVENUE'] });
  const budgets = useBudgets();
  const { isModalOpen, openModal, closeModal } = useModal();
  const [editingCategory, setEditingCategory] = useState<undefined | string>(
    undefined
  );
  const editModal = useModal();

  const onEdit = (id: string) => () => {
    setEditingCategory(id);
    editModal.openModal();
  };

  const onEditClose = () => {
    setEditingCategory(undefined);
    editModal.closeModal();
  };

  const renderBudget = useCallback(
    ({ id }: Category) => (
      <BudgetColumn
        onEdit={onEdit(id)}
        isLoading={budgets.isLoading}
        value={budgets.data?.[id]?.value}
        current={budgets.data?.[id]?.current}
      />
    ),
    [budgets]
  );

  return (
    <Page>
      <PageHeader title="Categories">
        <Button size="small" type="button" onClick={openModal}>
          Add Category
        </Button>
      </PageHeader>
      <Table
        cellClassName="h-12"
        columns={[
          {
            id: 'name',
            render: ({ name }: Account) => (
              <TwoLineCell icon={categoryIcon(name)}>
                <TwoLineCell.Row1>{name}</TwoLineCell.Row1>
              </TwoLineCell>
            ),
          },
          {
            id: 'budget',
            label: 'Monthly Budget',
            className: 'justify-end',
            render: renderBudget,
          },
        ]}
        data={Object.values(categories.data || {})}
      />
      <AddCategoryModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <EditCategoryBudgetModal
        isModalOpen={editModal.isModalOpen}
        closeModal={onEditClose}
        currentValue={
          editingCategory ? budgets.data?.[editingCategory]?.value : undefined
        }
        category={editingCategory || ''}
      />
    </Page>
  );
};
