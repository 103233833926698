import { useQuery, UseQueryResult } from 'react-query';
import { fetcher, Schemas } from './index';
import { ForexRates, Stock } from '../contracts';
import ApiError from './ApiError';
import { useAuthContext } from '../context/AuthContext';

export const getStocks = async (
  query: string,
  accessToken: string | undefined
): Promise<Array<Schemas['Stock']>> => {
  return fetcher(
    '/meta/stocks',
    'GET',
    undefined,
    {
      Authorization: `Bearer ${accessToken}`,
    },
    {
      query,
    }
  );
};

export const useQueryStocks = ({
  query,
}: {
  query: string;
}): UseQueryResult<Array<Stock>> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<Array<Schemas['Stock']>, ApiError, Array<Stock>>(
    ['stocks', query],
    async (): Promise<Array<Schemas['Stock']>> => {
      const accessToken = await getAccessToken();
      return getStocks(query, accessToken);
    }
  );
};

export const useForexRates = (): UseQueryResult<ForexRates> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<Schemas['Rates'], ApiError, ForexRates>(
    'forexRates',
    async (): Promise<Schemas['Rates']> => {
      const accessToken = await getAccessToken();
      return fetcher('/meta/forex/rates', 'GET', undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      select: (result) => result.rates as ForexRates,
    }
  );
};
