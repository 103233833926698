import { HTMLProps, ReactElement, useState } from 'react';
import clsx from 'clsx';

export interface ImageProps extends HTMLProps<HTMLImageElement> {
  hideOnError?: boolean;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
}

export const Image = ({
  className,
  alt,
  hideOnError,
  ...rest
}: ImageProps): ReactElement => {
  const [hidden, setHidden] = useState<boolean>(false);

  const onError = () => {
    if (hideOnError) {
      setHidden(true);
    }
  };

  const classNames = clsx(className, {
    invisible: hidden,
  });

  return <img className={classNames} alt="" onError={onError} {...rest} />;
};
