import { ReactElement } from 'react';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAccounts, useDeleteAccount } from '../api/accounts';
import { Money } from '../components/Money';
import { Table, timeRenderer, TwoLineCell } from '../components/Table';
import { Account } from '../contracts';
import { Page, PageHeader } from '../components/Page';
import { Button } from '../components/Button';
import { useAuthedFetch } from '../api/useAuthedFetch';
import { useModal } from '../hooks/modal';
import { AddAccountModal } from './AddAccountModal';
import { TableOptions } from '../components/TableOptions';
import { useConnections } from '../api/connections';

const AccountOptions = ({
  syncAccount,
  deleteAccount,
  isConnected,
}: {
  syncAccount: () => void;
  deleteAccount: () => void;
  isConnected: boolean;
}): ReactElement => {
  if (isConnected) {
    return (
      <TableOptions
        options={[
          { label: 'Delete', onClick: deleteAccount, icon: TrashIcon },
          { label: 'Sync', onClick: syncAccount, icon: ArrowPathIcon },
        ]}
      />
    );
  }
  return (
    <TableOptions
      options={[{ label: 'Delete', onClick: deleteAccount, icon: TrashIcon }]}
    />
  );
};

export const AccountsContainer = (): ReactElement => {
  const accounts = useAccounts({ types: ['ASSET', 'LIABILITY'] });
  const connections = useConnections();
  const { isModalOpen, openModal, closeModal } = useModal();
  const authedFetch = useAuthedFetch();
  const deleteAccount = useDeleteAccount();

  const doSyncAccount = (accountId: string) => () => {
    authedFetch(`/accounts/${accountId}/sync`, 'POST', {});
  };
  const doDeleteAccount = (accountId: string) => () => {
    deleteAccount.mutate(accountId);
  };

  const sorted = Object.values(accounts.data || {}).sort((a, b) =>
    a.accountType + a.category < b.accountType + b.category ? -1 : 1
  );

  return (
    <Page>
      <PageHeader title="Accounts">
        <Button size="small" type="button" onClick={openModal}>
          Add Account
        </Button>
      </PageHeader>
      <Table
        loading={accounts.isLoading}
        cellClassName="h-14"
        columns={[
          {
            id: 'name',
            render: ({ name, alias, connection }: Account) => (
              <TwoLineCell
                src={connections.data?.[connection]?.logoUri}
                alt={connections.data?.[connection]?.source}
              >
                <TwoLineCell.Row1>{alias || name}</TwoLineCell.Row1>
                <TwoLineCell.Row2>{alias && name}</TwoLineCell.Row2>
              </TwoLineCell>
            ),
          },
          {
            id: 'classification',
            label: 'Type',
            render: ({ category, accountType }: Account) => (
              <span className="text-gray-400">
                {[accountType, category].join(' | ')}
              </span>
            ),
          },
          {
            id: 'lastUpdate',
            label: 'Last Updated',
            render: timeRenderer('lastUpdate', true),
          },
          {
            id: 'balance',
            className: 'justify-end',
            render: ({ balance, currency }: Account) => (
              <Money value={balance} currency={currency} />
            ),
          },
          {
            id: 'actions',
            className: 'justify-end pr-2 flex relative',
            render: ({ id, connection }: Account) => (
              <AccountOptions
                isConnected={!!connection}
                syncAccount={doSyncAccount(id)}
                deleteAccount={doDeleteAccount(id)}
              />
            ),
          },
        ]}
        data={sorted}
      />
      <AddAccountModal closeModal={closeModal} isModalOpen={isModalOpen} />
    </Page>
  );
};
