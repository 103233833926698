import { Menu, Transition } from '@headlessui/react';
import { ElementType, Fragment, ReactElement } from 'react';
import { IconProps } from 'react-feather';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

interface TableOption {
  icon?: ElementType<IconProps>;
  label: string;
  onClick: () => void;
}

interface TableOptionsProps {
  label?: string;
  options: TableOption[];
}

export const TableOptions = ({
  options,
  label,
}: TableOptionsProps): ReactElement => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none items-center">
        {label || 'Options'}
        <EllipsisVerticalIcon className="w-5 h-5 ml-2 -mr-1" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-50"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
          {options.map(({ label: optionLabel, icon: Icon, onClick }) => {
            return (
              <Menu.Item key={optionLabel}>
                <button
                  onClick={onClick}
                  type="button"
                  className="group flex text-gray-700 block px-4 py-2 text-sm items-center w-full hover:bg-gray-50"
                >
                  {Icon && <Icon className="w-5 h-5 mr-2" />}
                  {optionLabel}
                </button>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

TableOptions.defaultProps = {
  label: undefined,
};
