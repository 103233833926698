export const parseDate = (
  dateAsString?: string | undefined
): Date | undefined => {
  if (!dateAsString) {
    return undefined;
  }
  return new Date(Date.parse(dateAsString));
};

export const times = <T>(n: number, fn: (key: number) => T): Array<T> => {
  return [...Array(n)].map((index) => fn(index));
};

/**
 * Compares the similarity between two strings using an n-gram comparison method.
 * Source: https://stackoverflow.com/a/62216738
 * The grams default to length 2.
 * @param str1 The first string to compare.
 * @param str2 The second string to compare.
 * @param gramSize The size of the grams. Defaults to length 2.
 */
export function stringSimilarity(
  str1: string,
  str2: string,
  gramSize = 2
): number {
  function getNGrams(str: string, len: number) {
    const s = ' '.repeat(len - 1) + str.toLowerCase() + ' '.repeat(len - 1);
    const v = new Array(s.length - len + 1);
    for (let i = 0; i < v.length; i += 1) {
      v[i] = s.slice(i, i + len);
    }
    return v;
  }

  if (!str1?.length || !str2?.length) {
    return 0.0;
  }

  // Order the strings by length so the order they're passed in doesn't matter
  // and so the smaller string's ngrams are always the ones in the set
  const s1 = str1.length < str2.length ? str1 : str2;
  const s2 = str1.length < str2.length ? str2 : str1;

  const pairs1 = getNGrams(s1, gramSize);
  const pairs2 = getNGrams(s2, gramSize);
  const set = new Set<string>(pairs1);

  const total = pairs2.length;
  const hits = pairs2.reduce((count, item) => {
    return set.delete(item) ? count + 1 : count;
  }, 0);
  return hits / total;
}
