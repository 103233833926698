import { useQuery, UseQueryResult } from 'react-query';
import { fetcher, Schemas } from './index';
import { Connection } from '../contracts';
import { useAuthContext } from '../context/AuthContext';
import ApiError from './ApiError';

export const useConnections = (): UseQueryResult<
  Record<string, Connection>
> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<
    Array<Schemas['Connection']>,
    ApiError,
    Record<string, Connection>
  >(
    'connections',
    async (): Promise<Array<Schemas['Connection']>> => {
      const accessToken = await getAccessToken();

      return fetcher('/connections', 'GET', undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      select: (connections) =>
        connections.reduce(
          (result, connection) => ({
            ...result,
            [connection.id]: {
              id: connection.id,
              logoUri: connection.logo_uri,
              provider: connection.provider,
              source: connection.source,
            },
          }),
          {}
        ),
    }
  );
};
