import { ReactElement } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { SelectField, BaseInputProps, FormInput } from './Input';
import {
  PostDestination,
  UsePostingDestinationsReturns,
} from '../api/accounts';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export function DestinationSelect({
  onChange,
  value,
  destinations,
}: {
  value: string | undefined;
  onChange: (newValue: PostDestination | null) => void;
  destinations: UsePostingDestinationsReturns;
}): ReactElement {
  const mappedValue = value
    ? destinations.data
        .flatMap((o) => o.options)
        .filter((o) => value.includes(o.value))
    : undefined;

  const oc = (newValue: PostDestination | null) => {
    onChange(newValue);
  };

  return (
    <SelectField<PostDestination>
      placeholder="Categorise..."
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1,
        }),
      }}
      isLoading={destinations.isLoading}
      isDisabled={destinations.isLoading}
      value={mappedValue}
      onChange={oc}
      options={destinations.data}
    />
  );
}

export interface DestinationSelectProps
  extends UseControllerProps<any>, // eslint-disable-line
    Omit<
      StateManagerProps<PostDestination, true>,
      'defaultValue' | 'name' | 'onChange' | 'value'
    >,
    BaseInputProps {
  destinations: UsePostingDestinationsReturns;
}

export function DestinationSelectField({
  name,
  rules,
  defaultValue,
  control,
  label,
  className,
  showErrors,
  ...rest
}: DestinationSelectProps): ReactElement {
  const { field, fieldState } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const { onChange, value, ...fieldRest } = field;
  return (
    <FormInput
      className={className}
      name={name}
      label={label}
      required={!!rules?.required}
      fieldState={fieldState}
      showErrors={showErrors}
    >
      <DestinationSelect
        value={value}
        onChange={(newValue: PostDestination | null) => {
          onChange(newValue?.value);
        }}
        {...fieldRest}
        {...rest}
      />
    </FormInput>
  );
}
