import { InputHTMLAttributes, ReactElement, PropsWithChildren } from 'react';
import Select, {
  GroupBase,
  CommonProps as ReactSelectProps,
} from 'react-select';
import {
  ControllerFieldState,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import clsx from 'clsx';
import StateManagedSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface BaseInputProps {
  label?: string;
  showErrors?: boolean;
}

interface FormInputProps extends BaseInputProps {
  name: string;
  fieldState?: ControllerFieldState;
  required?: boolean;
  className?: string;
}

export const FormInput = ({
  label,
  children,
  name,
  fieldState,
  required,
  showErrors,
  className,
}: PropsWithChildren<FormInputProps>): ReactElement => (
  <div className={clsx(className, 'block mb-4')}>
    {label && (
      <label className="text-sm mb-1 block" htmlFor={name}>
        {label}
        {required && '*'}
      </label>
    )}
    {children}
    {(showErrors || showErrors === undefined) && (
      <>
        {fieldState?.isTouched && fieldState?.error?.type === 'required' && (
          <span className="text-xs border-red-400">Required</span>
        )}
      </>
    )}
  </div>
);

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  isErrored?: boolean;
  before?: string;
  after?: string;
}

export const InputField = ({
  isErrored,
  className,
  before,
  after,
  ...rest
}: InputFieldProps): ReactElement => {
  const classNames = clsx(
    className,
    'fs-exclude appearance-none rounded-md relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-green focus:border-green-700 sm:text-sm sm:leading-5 w-full',
    {
      'border-red-400': isErrored,
    }
  );
  return (
    <div className="relative rounded-md shadow-sm">
      {before && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
          <span className="text-gray-500 leading-none text-sm">{before}</span>
        </div>
      )}
      <input className={classNames} {...rest} />
      {after && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <span className="text-gray-500 leading-none text-sm">{after}</span>
        </div>
      )}
    </div>
  );
};

interface InputProps
  extends UseControllerProps<any>, // eslint-disable-line
    Omit<InputFieldProps, 'defaultValue' | 'name'>,
    BaseInputProps {
  inputClassName?: string;
}

export const Input = (props: InputProps): ReactElement => {
  const {
    label,
    showErrors,
    className,
    inputClassName,
    name,
    rules,
    defaultValue,
    control,
    ...rest
  } = props;
  const { field, fieldState } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const { error, isTouched } = fieldState;
  const { name: fieldName, ...fieldRest } = field;
  return (
    <FormInput
      name={name}
      required={!!rules?.required}
      label={label}
      fieldState={fieldState}
      className={className}
      showErrors={showErrors}
    >
      <InputField
        name={fieldName}
        className={inputClassName}
        isErrored={!!error && isTouched}
        {...fieldRest}
        {...rest}
      />
    </FormInput>
  );
};

Input.defaultProps = {
  inputClassName: undefined,
};

type SelectFieldProps<
  OptionType = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
> = StateManagerProps<OptionType, IsMulti, GroupType>;

export function SelectField<T>(props: SelectFieldProps<T>): ReactElement {
  const { styles, ...rest } = props;
  return (
    <Select
      styles={{
        control: (base) => ({ ...base, borderRadius: 6 }),
        valueContainer: (base) => ({ ...base, fontSize: 14 }),
        ...styles,
      }}
      {...rest}
    />
  );
}

interface SelectProps
  extends UseControllerProps<any>,
    BaseInputProps,
    Omit<SelectFieldProps, 'defaultValue' | 'name'> {
  // eslint-disable-line
}

export function SelectInput({
  name,
  rules,
  defaultValue,
  control,
  label,
  showErrors,
  ...rest
}: SelectProps): ReactElement {
  const { field, fieldState } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  return (
    <FormInput
      name={name}
      label={label}
      required={!!rules?.required}
      fieldState={fieldState}
      showErrors={showErrors}
    >
      <SelectField {...field} {...rest} />
    </FormInput>
  );
}
