import React, { ReactElement, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';

export const LogoutContainer = (): ReactElement => {
  const { logout } = useAuthContext();
  useEffect(() => {
    logout();
  }, []);

  return <></>;
};
