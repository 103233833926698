import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { times } from '../utils';
import { Button } from './Button';
import { Text } from './Text';

interface CardProps {
  className?: string;
}

export const Card = ({
  children,
  className,
}: PropsWithChildren<CardProps>): ReactElement => {
  return (
    <div className={clsx('border rounded shadow-sm p-6', className)}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: undefined,
};

export const CardHeader = ({
  children,
  title,
}: PropsWithChildren<{ title: string }>): ReactElement => {
  return (
    <div className="mb-6">
      <Text variant="h2">{title}</Text>
      {children}
    </div>
  );
};

export const CardFooter = ({
  to,
  label,
}: {
  to: string;
  label: string;
}): ReactElement => {
  return (
    <div className="flex align-right mt-8">
      <Link to={to}>
        <Button size="small">{label}</Button>
      </Link>
    </div>
  );
};

interface CardPlaceholderProps {
  height?: number;
  count?: number;
}

export const CardPlaceholder = ({
  height = 4,
  count = 3,
}: CardPlaceholderProps): ReactElement => {
  return (
    <div className="w-full">
      <div className="animate-pulse">
        {times(count - 1, (key) => (
          <div
            key={key}
            className={`flex-1 bg-gray-200 h-${height} mb-2 rounded`}
          />
        ))}
        <div className={`flex-1 bg-gray-200 h-${height} rounded`} />
      </div>
    </div>
  );
};

CardPlaceholder.defaultProps = {
  height: 4,
  count: 3,
};
