import { PropsWithChildren, ReactElement } from 'react';
import clsx from 'clsx';

export const Tabs = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => (
  <ul className="w-full flex flex-row border-b border-b-1 border-gray-200 mb-4">
    {children}
  </ul>
);

export const Tab = ({
  children,
  onClick,
  active,
  disabled,
}: PropsWithChildren<{
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}>): ReactElement => (
  <li
    className={clsx(' text-sm hover:font-semibold hover:border-b', {
      'border-b-2': active,
    })}
  >
    <button
      disabled={disabled}
      type="button"
      onKeyDown={onClick}
      onClick={onClick}
      className="pl-4 pr-4 p-2 focus:outline-none"
    >
      {children}
    </button>
  </li>
);
