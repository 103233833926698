import { ReactElement, useCallback } from 'react';
import { ScissorsIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Transaction } from '../contracts';
import {
  PostDestination,
  UsePostingDestinationsReturns,
} from '../api/accounts';
import {
  UsePostReturns,
  UseResetTransactionReturns,
} from '../api/transactions';
import { DestinationSelect } from './TransactionDestinationSelect';
import { TableOptions } from './TableOptions';
import { Button } from './Button';

interface TransactionsTableRowActionsProps {
  transaction: Transaction;
  postingDestinations: UsePostingDestinationsReturns;
  postTransaction: UsePostReturns;
  resetTransaction: UseResetTransactionReturns;
  openMatchModal: () => void;
  openSplitModal: () => void;
}

export const TransactionsTableRowActions = ({
  transaction,
  postingDestinations,
  postTransaction,
  resetTransaction,
  openMatchModal,
  openSplitModal,
}: TransactionsTableRowActionsProps): ReactElement => {
  const reset = useCallback(() => {
    resetTransaction.mutate({ id: transaction.parent || transaction.id });
  }, [resetTransaction, transaction]);

  const onChange = useCallback(
    (change: PostDestination | null) => {
      if (change) {
        postTransaction.mutate({
          id: transaction.id,
          destinations: [
            {
              account_id: change.value,
              value: transaction.value * -1,
            },
          ],
        });
      }
    },
    [transaction, postTransaction]
  );

  const value = transaction.entries.find(
    (i) => i.accountId !== transaction.account
  )?.accountId;

  return (
    <>
      <DestinationSelect
        destinations={postingDestinations}
        onChange={onChange}
        value={value}
      />
      <span className="mr-2 ml-2">or</span>
      {value ? (
        <Button onClick={reset} colour="secondary">
          Reset
        </Button>
      ) : (
        <TableOptions
          label="Advanced"
          options={[
            {
              label: 'Match',
              icon: MagnifyingGlassIcon,
              onClick: openMatchModal,
            },
            {
              label: 'Split',
              icon: ScissorsIcon,
              onClick: openSplitModal,
            },
          ]}
        />
      )}
    </>
  );
};
