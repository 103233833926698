import { ReactElement } from 'react';
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable';
import { useController, UseControllerProps } from 'react-hook-form';
import { BaseInputProps, FormInput } from './Input';
import { GroupBase } from 'react-select';

type OptionType<T> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

interface SelectProps<T>
  extends UseControllerProps<any>,
    Omit<
      AsyncCreatableProps<OptionType<T>, false, GroupBase<OptionType<T>>>,
      'defaultValue' | 'name'
    >,
    BaseInputProps {}

export function AutocompleteSelect<T>({
  name,
  rules,
  defaultValue,
  control,
  label,
  loadOptions,
  className,
  showErrors,
  ...rest
}: SelectProps<T>): ReactElement {
  const { field, fieldState } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const { onChange, value, ...fieldRest } = field;
  return (
    <FormInput
      className={className}
      name={name}
      label={label}
      required={!!rules?.required}
      fieldState={fieldState}
      showErrors={showErrors}
    >
      <AsyncCreatableSelect<OptionType<T>, false>
        cacheOptions
        loadOptions={loadOptions}
        onChange={(v) => onChange(v?.value)}
        {...fieldRest}
        {...rest}
      />
    </FormInput>
  );
}
