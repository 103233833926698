import { ReactElement } from 'react';
import { NetworthGraph } from '../components/NetworthGraph';
import { Page, PageHeader } from '../components/Page';

export const InsightsContainer = (): ReactElement => {
  return (
    <Page>
      <PageHeader title="Insights" />
      <NetworthGraph
        data={{
          '2020-01': 10000,
          '2020-02': 12000,
        }}
      />
    </Page>
  );
};
