import { useLocation, useNavigate } from 'react-router-dom';

export const useQuery = (): URLSearchParams =>
  new URLSearchParams(useLocation().search);

export type UseQueryStateReturns<T> = [T | undefined, (param: T) => void];

export interface UseQueryStateOptions<T> {
  push?: boolean;
  encoder?: (param: T) => string;
  decoder?: (value: string) => T;
}

export function useQueryState<T>(
  name: string,
  defaultValue: undefined | T = undefined,
  options: UseQueryStateOptions<T> = {}
): UseQueryStateReturns<T> {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const {
    push = true,
    encoder = JSON.stringify,
    decoder = JSON.parse,
  } = options;
  const currentValue = query.get(name);
  return [
    currentValue
      ? currentValue === 'undefined'
        ? undefined
        : (decoder(currentValue) as T)
      : defaultValue,
    (param) => {
      query.set(name, encoder(param));
      navigate(
        {
          pathname: location.pathname,
          search: query.toString(),
        },
        { replace: !push }
      );
    },
  ];
}
