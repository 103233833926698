import { ReactElement } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardPlaceholder,
} from '../../components/Card';
import { useTransactions } from '../../api/transactions';
import { Money } from '../../components/Money';

export const RecentTransactionsCard = (): ReactElement => {
  const transactions = useTransactions({ limit: 5, reverse: true });
  return (
    <Card className="mb-4 md:mb-8">
      <CardHeader title="Recent transactions" />
      {transactions.isLoading && <CardPlaceholder />}
      {!transactions.isLoading &&
        transactions.data?.map((t) => (
          <div className="text-sm flex justify-between mb-2" key={t.id}>
            <span className="mr-2">{t.description}</span>
            <Money value={t.value} currency={t.currency} />
          </div>
        ))}
      <CardFooter label="View all transactions" to="/transactions" />
    </Card>
  );
};
