import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import { Check } from 'react-feather';
import { Link } from 'react-router-dom';
import { useAccounts } from '../../api/accounts';
import { Card, CardHeader } from '../../components/Card';

interface TickBoxProps {
  ticked?: boolean;
  num: string;
}

export const TickBox = ({
  ticked,
  num,
  children,
}: PropsWithChildren<TickBoxProps>): ReactElement => {
  return (
    <li
      className={clsx('mb-2 flex items-center', {
        'line-through': ticked,
      })}
    >
      <div
        className={clsx(
          'inline-block h-8 w-8 rounded-full inline-flex justify-center items-center mr-4',
          {
            'bg-green-900': ticked,
            'bg-gray-200 text-gray-400': !ticked,
          }
        )}
      >
        {ticked ? <Check color="white" height={16} /> : num}
      </div>
      {children}
    </li>
  );
};

TickBox.defaultProps = {
  ticked: false,
};

export const TodayCard = (): ReactElement => {
  const accounts = useAccounts({ types: ['ASSET', 'LIABILITY'] });
  const categories = useAccounts({ types: ['REVENUE', 'EXPENSE'] });
  const hasAccounts = Object.keys(accounts.data || {}).length !== 0;
  const hasCategories = Object.keys(categories.data || {}).length !== 0;
  const isOnboarding = !hasCategories || !hasAccounts;
  return (
    <Card className="mb-4 md:mb-8">
      <CardHeader title={isOnboarding ? 'Get set up' : "Today's Tasks"} />
      <ul>
        <TickBox num="1" ticked={hasAccounts}>
          <Link to="/accounts">Connect your accounts</Link>
        </TickBox>
        <TickBox num="2" ticked={hasCategories}>
          <Link to="/categories">Setup categories</Link>
        </TickBox>
        <TickBox num="3">
          <Link to="/transactions">Tag your first transaction</Link>
        </TickBox>
      </ul>
    </Card>
  );
};
