import clsx from 'clsx';
import { ReactElement } from 'react';

export interface BadgeProps {
  value: string;
  className?: string;
  color?: 'blue' | 'green' | 'red';
}

export const Badge = ({
  value,
  className,
  color = 'blue',
}: BadgeProps): ReactElement => {
  return (
    <div
      className={clsx(
        className,
        `rounded-full bg-${color}-400 text-${color}-900 px-2 py-1 inline-block text-xs leading-none font-bold`
      )}
    >
      {value}
    </div>
  );
};
