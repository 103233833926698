import { ReactElement } from 'react';
import { Image } from './Image';
import { useAccounts } from '../api/accounts';
import { useConnections } from '../api/connections';

export function AccountLabel({
  accountId,
}: {
  accountId: string;
}): ReactElement {
  const accounts = useAccounts();
  const connections = useConnections();
  const account = accounts.data?.[accountId];
  if (!account) {
    return <></>;
  }
  const connection = connections.data?.[account.connection];
  return (
    <div className="flex justify-center items-center">
      {connection && connection?.logoUri ? (
        <Image
          className="h-4 mr-2"
          src={connection.logoUri}
          alt={account.name}
        />
      ) : (
        <></>
      )}
      {account.alias || account.name}
    </div>
  );
}
