import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { ApiResponse, fetcher, Schemas } from './index';
import {
  Account,
  AccountCategory,
  AccountId,
  BalanceSheetAccountTypes,
  CategoryId,
  Currency,
  IncomeAccountTypes,
  PostDestinationType,
} from '../contracts';
import { useAuthContext } from '../context/AuthContext';
import ApiError from './ApiError';
import { useConverter } from '../components/Money';

export interface AccountsParams {
  types?: Array<BalanceSheetAccountTypes | IncomeAccountTypes>;
}

export const useAccounts = (
  params?: AccountsParams
): UseQueryResult<Record<string, Account>> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<Array<Schemas['Account']>, ApiError, Record<string, Account>>(
    ['accounts', params],
    async (): Promise<Array<Schemas['Account']>> => {
      const accessToken = await getAccessToken();

      return fetcher(
        '/accounts',
        'GET',
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        params
      );
    },
    {
      refetchOnWindowFocus: false,
      select: (accounts) =>
        accounts.reduce(
          (currentvalue, account) => ({
            ...currentvalue,
            [account.id]: {
              id: account.id,
              name: account.name,
              balance: account.balance,
              accountType: account.type,
              lastUpdate:
                account.last_update &&
                new Date(Date.parse(account.last_update)),
              category: account.category as AccountCategory,
              currency: account.currency as Currency,
              connection: account.connection_id,
              alias: account.alias,
            },
          }),
          {}
        ),
    }
  );
};

export type PostDestination = {
  label: string;
  value: AccountId | CategoryId;
  destination: PostDestinationType;
};

export interface UsePostingDestinationsReturns {
  isLoading: boolean;
  data: Array<{
    label: string;
    options: Array<PostDestination>;
  }>;
}

export const usePostingDestinations = (
  categoryLabel: string,
  accountLabel: string
): UsePostingDestinationsReturns => {
  const accounts = useAccounts({ types: ['ASSET', 'LIABILITY'] });
  const categories = useAccounts({ types: ['REVENUE', 'EXPENSE'] });
  const postingOptions = [
    {
      label: categoryLabel,
      options: Object.values(categories.data || {}).map((c) => ({
        value: c.id,
        label: c.name,
        destination: PostDestinationType.Category,
      })),
    },
    {
      label: accountLabel,
      options: Object.values(accounts.data || {}).map((k) => ({
        value: k.id,
        label: k.name,
        destination: PostDestinationType.Account,
      })),
    },
  ];

  return {
    isLoading: accounts.isLoading || categories.isLoading,
    data: postingOptions,
  };
};

interface AccountFiltered {
  data: Array<Account>;
  total: number;
  isLoading: boolean;
}

export const useAccountsFiltered = (
  filter: (account: Account) => boolean,
  targetCurrency?: Currency
): AccountFiltered => {
  const { data, ...rest } = useAccounts();
  const filtered = Object.values(data || {}).filter((account) =>
    filter(account)
  );
  const converter = useConverter();
  const total = filtered.reduce(
    (running, account) =>
      running +
      (targetCurrency && account.currency !== targetCurrency
        ? converter(account.currency, targetCurrency, account.balance)
        : account.balance),
    0
  );
  return {
    data: filtered,
    total,
    ...rest,
  };
};

export type CreateAccountParams = Omit<Schemas['CreateAccount'], 'type'> & {
  accountType: BalanceSheetAccountTypes | IncomeAccountTypes;
};

export const useCreateAccount = (): UseMutationResult<
  ApiResponse<Schemas['Account']>,
  Error,
  CreateAccountParams
> => {
  const { getAccessToken } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation(
    async (values: CreateAccountParams) => {
      const accessToken = await getAccessToken();
      const { accountType: type, ...rest } = values;
      return fetcher(
        '/accounts',
        'POST',
        { ...rest, type },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accounts');
      },
    }
  );
};

export const useDeleteAccount = (): UseMutationResult<
  unknown,
  Error,
  string
> => {
  const { getAccessToken } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const accessToken = await getAccessToken();
      return fetcher(`/accounts/${id}`, 'DELETE', undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accounts');
      },
    }
  );
};
