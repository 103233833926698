import { ReactElement, useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Modal, ModalProps } from '../components/modal/Modal';
import { Money } from '../components/Money';
import { Card } from '../components/Card';
import { Transaction } from '../contracts';
import { UsePostingDestinationsReturns } from '../api/accounts';
import { UsePostReturns } from '../api/transactions';
import { DestinationSelectField } from '../components/TransactionDestinationSelect';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useProfileContext } from '../context/ProfileContext';
import currency from 'currency.js';

interface SplitTransactionModalProps extends Omit<ModalProps, 'title' | 'id'> {
  editing?: Transaction;
  postTransaction: UsePostReturns;
  destinations: UsePostingDestinationsReturns;
}

interface FormData {
  destinations: Array<{ accountId: string; value: number }>;
}

const defaultValue = { accountId: '', value: 0 };

export const SplitTransactionModal = ({
  closeModal,
  editing: transaction,
  destinations,
  postTransaction,
  ...rest
}: SplitTransactionModalProps): ReactElement => {
  const transactionValue = transaction ? transaction.value / 100 : 0;
  const schema = useMemo(
    () =>
      yup.object().shape({
        destinations: yup
          .array()
          .of(
            yup.object().shape({
              accountId: yup.string().required(),
              value: yup.number().required(),
            })
          )
          .test('sum-value', 'Error si fish', (d) => {
            const total = d
              ? d.reduce(
                  (sum, dest) => sum.add(dest.value ? dest.value : 0),
                  currency(0)
                )
              : currency(-1);
            return (
              total.value === currency(transactionValue).multiply(-1).value
            );
          }),
      }),
    [transaction]
  );

  const { getCurrencySymbol } = useProfileContext();
  const { reset, handleSubmit, control, formState } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      destinations: [defaultValue, defaultValue],
    },
  });
  const { isValid } = formState;

  const destinationsFields = useFieldArray<FormData>({
    control,
    name: 'destinations',
  });

  const onModalClosed = useCallback(() => {
    reset();
  }, [reset]);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (transaction) {
        await postTransaction.mutateAsync({
          id: transaction.id,
          destinations: values.destinations
            .filter((d) => d.accountId !== undefined)
            .map((d) => ({
              account_id: d.accountId as string,
              value: currency(d.value).multiply(100).value,
            })),
        });
        closeModal();
      }
    },
    [postTransaction, closeModal, transaction]
  );

  return (
    <Modal
      title="Split Transaction"
      onModalClosed={onModalClosed}
      closeModal={closeModal}
      {...rest}
    >
      <Card className="mb-4 flex flex-row justify-between">
        <div>{transaction?.description}</div>
        <div>
          {transaction && (
            <Money currency={transaction.currency} value={transaction.value} />
          )}
        </div>
      </Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        {destinationsFields.fields.map((field, index) => (
          <div key={field.id} className="flex flex-row w-full">
            <DestinationSelectField
              label="Category/Account"
              destinations={destinations}
              name={`destinations.${index}.accountId`}
              rules={{ required: true }}
              control={control}
              className="flex-1"
            />
            <Input
              className="ml-2"
              type="number"
              step="0.01"
              label="Amount"
              inputClassName="pl-7 pr-12"
              before={transaction && getCurrencySymbol(transaction?.currency)}
              after={transaction?.currency.toUpperCase()}
              rules={{ required: true }}
              control={control}
              name={`destinations.${index}.value`}
              showErrors={false}
            />

            <button
              type="button"
              onClick={() => destinationsFields.remove(index)}
              className="mt-1 ml-2 outine-none"
            >
              <TrashIcon color="gray" height="16" />
            </button>
          </div>
        ))}
        <div className="flex justify-between mt-4 border-top">
          <Button
            colour="secondary"
            onClick={() => destinationsFields.append(defaultValue)}
            disabled={destinations.isLoading || postTransaction.isLoading}
          >
            Add Row
          </Button>
          <Button
            type="submit"
            disabled={
              destinations.isLoading || !isValid || postTransaction.isLoading
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

SplitTransactionModal.defaultProps = {
  editing: undefined,
};
