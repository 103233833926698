import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

export const config = {
  apiKey: 'AIzaSyDk1iXEFaselHVv_NEcmt4W04vgphSY-hA',
  authDomain: 'equity-309506.firebaseapp.com',
  projectId: 'equity-309506',
  storageBucket: 'equity-309506.appspot.com',
  messagingSenderId: '689659342266',
  appId: '1:689659342266:web:b684003cc3342644dd4183',
  databaseURL: '',
};

const app = initializeApp(config);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
