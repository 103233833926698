import {
  Coffee,
  Home,
  Icon,
  ShoppingBag,
  ShoppingCart,
  Zap,
  Phone,
} from 'react-feather';
import { stringSimilarity } from './utils';

const categoryIcons: Record<string, Icon> = {
  coffee: Coffee,
  rent: Home,
  utilities: Zap,
  shopping: ShoppingBag,
  groceries: ShoppingCart,
  phone: Phone,
};

export const categoryIcon = (categoryName: string): Icon | undefined => {
  const key = Object.keys(categoryIcons).find((name: string) => {
    return stringSimilarity(name, categoryName) > 0.6;
  });
  return key ? categoryIcons[key] : undefined;
};
