import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { format } from 'date-fns/esm';
import { ApiResponse, fetcher, Schemas } from './index';
import { Currency, Family, Profile } from '../contracts';
import ApiError from './ApiError';
import { parseDate } from '../utils';
import { useAuthContext } from '../context/AuthContext';

export const useFamilies = (): UseQueryResult<Array<Family>> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<Array<Schemas['Family']>, ApiError, Array<Family>>(
    'families',
    async (): Promise<Array<Schemas['Family']>> => {
      const accessToken = await getAccessToken();

      return fetcher('/profile/families', 'GET', undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      select: (result) => result.map((f) => ({ id: f.id })),
    }
  );
};

export const useCreateFamily = (): UseMutationResult<
  ApiResponse<Schemas['Family']>,
  Error,
  Schemas['CreateOrJoinFamily']
> => {
  const { getAccessToken } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ code }) => {
      const accessToken = await getAccessToken();
      return fetcher(
        '/profile/families',
        'POST',
        { code },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('families');
      },
    }
  );
};

export const useProfile = (): UseQueryResult<Profile> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<Schemas['Profile'], ApiError, Profile>(
    'profile',
    async (): Promise<Schemas['Profile']> => {
      const accessToken = await getAccessToken();

      return fetcher('/profile/me', 'GET', undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      select: (data) => ({
        defaultFamilyId: data.default_family_id,
        email: data.email,
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        dateOfBirth: parseDate(data.date_of_birth),
        currency: data.default_currency as Currency,
      }),
    }
  );
};

export const useMutateProfile = (): UseMutationResult<
  ApiResponse<Schemas['Profile']>,
  Error,
  Partial<Profile>
> => {
  const { getAccessToken } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation(
    async (updates: Partial<Profile>) => {
      const accessToken = await getAccessToken();
      return fetcher(
        '/profile/me',
        'PATCH',
        {
          first_name: updates.firstName,
          last_name: updates.lastName,
          date_of_birth: updates.dateOfBirth
            ? format(updates.dateOfBirth, 'yyyy-MM-dd')
            : undefined,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('families');
      },
    }
  );
};
