export const buildTruelayerURL = (): string => {
  const BASE_URL =
    import.meta.env.VITE_TRUELAYER_BASE_URL ||
    'https://auth.truelayer-sandbox.com/';
  const redirectUri = `${window.location.origin}/callback`;
  const TRUELAYER_CLIENT_ID =
    import.meta.env.VITE_TRUELAYER_CLIENT_ID || 'sandbox-vita-9ba97c';
  const postifx = BASE_URL.includes('sandbox') ? '%20uk-cs-mock' : '';
  return `${BASE_URL}?response_type=code&client_id=${TRUELAYER_CLIENT_ID}&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=${redirectUri}&providers=uk-ob-all%20uk-oauth-all${postifx}`;
};
