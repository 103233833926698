import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { AuthPage } from '../components/AuthPage';
import { parseDate } from '../utils';
import { useCreateFamily, useMutateProfile } from '../api/profile';
import { useProfileContext } from '../context/ProfileContext';

interface FormData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export const OnboardingContainer = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { profile } = useProfileContext();
  const updateProfile = useMutateProfile();
  const createOrJoinFamily = useCreateFamily();

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
    },
  });

  const updateProfileAndJoin = async (data: FormData) => {
    setLoading(true);
    try {
      await updateProfile.mutateAsync({
        ...data,
        dateOfBirth: parseDate(data.dateOfBirth),
      });
      await createOrJoinFamily.mutateAsync({});
    } finally {
      setLoading(false);
    }
  };

  if (profile.defaultFamilyId) {
    return <Navigate to="/" />;
  }

  return (
    <AuthPage>
      <h1 className="text-2xl font-bold mb-10 text-center">
        Setup your account
      </h1>
      <form onSubmit={handleSubmit(updateProfileAndJoin)}>
        <div className="mb-4">
          <Input
            name="firstName"
            control={control}
            placeholder="Your first name"
          />
          <Input
            name="lastName"
            control={control}
            placeholder="Your last name"
          />
          <Input
            name="dateOfBirth"
            control={control}
            type="date"
            placeholder="Your date of birth"
          />
        </div>
        <Button type="submit" disabled={loading}>
          Setup
        </Button>
      </form>
    </AuthPage>
  );
};
