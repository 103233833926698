import { ReactElement, useMemo, useCallback } from 'react';
import {
  getUnixTime,
  sub,
  endOfMonth,
  startOfMonth,
  add,
  format,
  parse,
} from 'date-fns/esm';
import { Tab, Tabs } from '../components/Tabs';
import { usePostingDestinations } from '../api/accounts';
import {
  transactionsConverter,
  usePostTransaction,
  useResetTransaction,
  useInfiniteTransactions,
  TransactionParams,
  useMatchTransaction,
} from '../api/transactions';
import { Table, TwoLineCell, timeRenderer } from '../components/Table';
import { MatchTransactionModal } from './MatchTransactionModal';
import { Transaction } from '../contracts';
import { Money } from '../components/Money';
import { Page, PageHeader } from '../components/Page';
import { Button } from '../components/Button';
import { useEditModal } from '../hooks/modal';
import { AccountLabel } from '../components/AccountLabel';
import { SplitTransactionModal } from './SplitTransactionModal';
import { useQueryState } from '../location';
import { TransactionsTableRowActions } from '../components/TransactionsTableRowActions';
import { Schemas } from '../api';
import { Badge } from '../components/Badge';

const transactionsSplitter = (
  transaction: Schemas['Transaction']
): Array<Transaction> => {
  if (transaction.entries.length < 3) {
    return [transactionsConverter(transaction)];
  }
  return transaction.entries
    .filter((entry) => entry.account_id !== transaction.account_id)
    .map((entry, index) => {
      return transactionsConverter(
        {
          ...transaction,
          id: transaction.id + entry.id,
          entries: [entry],
          value: entry.value,
        },
        {
          parent: transaction.id,
          split: [index, transaction.entries.length - 1],
        }
      );
    });
};

const renderDescription = ({
  description,
  merchants,
  logo,
  split,
}: Transaction) => (
  <TwoLineCell src={logo} alt={description}>
    <TwoLineCell.Row1>
      {description}
      {split && (
        <Badge
          color="green"
          className="ml-2"
          value={`${split[0] + 1}/${split[1]}`}
        />
      )}
    </TwoLineCell.Row1>
    <TwoLineCell.Row2>{merchants.join(' | ')}</TwoLineCell.Row2>
  </TwoLineCell>
);
const renderAccount = ({ account }: Transaction) =>
  account ? <AccountLabel accountId={account} /> : <></>;

const renderValue = ({ value, currency }: Transaction) => (
  <Money value={value} currency={currency} className="font-semibold" />
);

export const TransactionsContainer = (): ReactElement => {
  const matchTransactionModal = useEditModal<Transaction>();
  const splitTransactionModal = useEditModal<Transaction>();
  const postingDestinations = usePostingDestinations(
    'Categorise',
    'Transfer to'
  );
  const [unposted, setUnposted] = useQueryState<boolean | undefined>(
    'unposted',
    true
  );
  const [rawCurrentMonth, setCurrentMonth] = useQueryState<Date>(
    'month',
    new Date(),
    {
      encoder: (d: Date) => format(d, 'yyyy-MM'),
      decoder: (v: string) => parse(v, 'yyyy-MM', new Date()),
    }
  );

  const currentMonth = startOfMonth(rawCurrentMonth || new Date());

  const endOfCurrentMonth = endOfMonth(currentMonth);
  const startOfPreviousMonth = startOfMonth(sub(currentMonth, { days: 1 }));
  const startOfNextMonth = startOfMonth(add(endOfCurrentMonth, { days: 1 }));

  const params: TransactionParams = {
    unposted,
    created: {
      gte: getUnixTime(currentMonth),
      lte: getUnixTime(endOfCurrentMonth),
    },
  };
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteTransactions(params);
  const matchTransaction = useMatchTransaction(params);
  const postTransaction = usePostTransaction(params);
  const resetTransaction = useResetTransaction(params);

  const transactionsData = useMemo(
    () =>
      data?.pages.flatMap((page) => page.data.flatMap(transactionsSplitter)) ||
      [],
    [data]
  );

  const renderPoster = useCallback(
    (item: Transaction) => (
      <TransactionsTableRowActions
        transaction={item}
        postingDestinations={postingDestinations}
        postTransaction={postTransaction}
        resetTransaction={resetTransaction}
        openMatchModal={() => matchTransactionModal.onEdit(item)}
        openSplitModal={() => splitTransactionModal.onEdit(item)}
      />
    ),
    [postTransaction, resetTransaction, postingDestinations]
  );

  const columns = useMemo(
    () => [
      { id: 'timestamp', render: timeRenderer('timestamp') },
      {
        id: 'description',
        render: renderDescription,
      },
      {
        id: 'account',
        render: renderAccount,
      },
      {
        id: 'value',
        className: 'justify-end pr-2',
        render: renderValue,
      },
      {
        id: 'poster',
        render: renderPoster,
        width: '1/4',
        label: '',
      },
    ],
    [renderPoster]
  );

  return (
    <Page>
      <PageHeader title="Transactions">
        <Button
          onClick={() => setCurrentMonth(startOfPreviousMonth)}
          size="small"
          className="mr-4"
          disabled={isFetching}
        >
          Previous
        </Button>
        <span>{format(currentMonth, 'MMM yyyy')}</span>
        <Button
          disabled={startOfNextMonth > new Date() || isFetching}
          onClick={() => setCurrentMonth(startOfNextMonth)}
          size="small"
          className="ml-4"
        >
          Next
        </Button>
      </PageHeader>
      <Tabs>
        <Tab
          disabled={isFetching}
          active={unposted === undefined}
          onClick={() => setUnposted(undefined)}
        >
          All
        </Tab>
        <Tab
          disabled={isFetching}
          active={unposted === true}
          onClick={() => setUnposted(true)}
        >
          Uncategorised
        </Tab>
        <Tab
          disabled={isFetching}
          active={unposted === false}
          onClick={() => setUnposted(false)}
        >
          Categorised
        </Tab>
      </Tabs>
      <Table
        cellClassName="h-16"
        loading={isFetching}
        data={transactionsData}
        columns={columns}
      />
      <div className="flex justify-between pt-2 pb-2">
        <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        <div className="flex">
          <Button
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={() => fetchNextPage()}
            className="ml-2"
            size="small"
          >
            {isFetchingNextPage
              ? 'Loading more...'
              : hasNextPage
              ? 'Load More'
              : 'Nothing more to load'}
          </Button>
        </div>
      </div>
      <MatchTransactionModal
        matchTransaction={matchTransaction}
        {...matchTransactionModal}
      />
      <SplitTransactionModal
        destinations={postingDestinations}
        postTransaction={postTransaction}
        {...splitTransactionModal}
      />
    </Page>
  );
};
