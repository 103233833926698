import React, { useContext, ReactElement, useCallback } from 'react';
import { useFamilies, useProfile } from '../api/profile';
import { Spinner } from '../components/Spinner';
import { Currency, Family, Profile } from '../contracts';

export interface ProfileContextProps {
  profile: Profile;
  families?: Array<Family>;
  currency: Currency;
  locale: string;
  getCurrencySymbol: (currency: Currency) => string;
}

export const ProfileContext = React.createContext<
  ProfileContextProps | undefined
>(undefined);

export function useProfileContext(): ProfileContextProps {
  const context = useContext(ProfileContext);
  if (!context) throw new Error('ProfileContext not initialized');
  return context;
}

const LOCALE = 'en-GB';

/**
 * Allows rendering of content outside of the current components tree
 */
export function ProfileContextProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const profile = useProfile();
  const families = useFamilies();

  const getCurrencySymbol = useCallback((currency: Currency) => {
    return (0)
      .toLocaleString(LOCALE, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }, []);
  if (!profile.data) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <ProfileContext.Provider
      value={{
        families: families.data,
        profile: profile.data,
        currency: profile.data.currency,
        getCurrencySymbol,
        locale: LOCALE,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
