import { ButtonHTMLAttributes, ElementType, ReactElement } from 'react';
import clsx from 'clsx';
import { IconProps } from 'react-feather';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'normal' | 'small';
  colour?: 'primary' | 'secondary';
  icon?: ElementType<IconProps>;
}

export const Button = (props: ButtonProps): ReactElement => {
  const {
    className,
    size,
    icon: Icon,
    disabled,
    colour,
    children,
    ...rest
  } = props;
  const classNames = clsx(
    className,
    'group relative inline-flex justify-center rounded-md border border-gray-300 shadow-sm leading-5 rounded-md focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 text-sm font-medium',
    {
      'py-2 px-4': size === 'normal',
      'py-1 px-2': size === 'small',
      'text-white focus:border-green-700 focus:shadow-outline-green active:bg-green-700 bg-green-900 hover:bg-green-800 disabled:bg-gray-10':
        colour === 'primary',
      'bg-white text-gray-700 hover:bg-gray-50': colour === 'secondary',
      'cursor-default': disabled,
    }
  );
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={classNames} disabled={disabled} {...rest}>
      {children}
      {Icon && <Icon className="w-5 h-5 ml-2 -mr-1" />}
    </button>
  );
};

Button.defaultProps = {
  size: 'normal',
  colour: 'primary',
  icon: undefined,
};
