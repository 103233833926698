import { ReactElement } from 'react';
import { Page, PageHeader } from '../../components/Page';
import { BalanceSheetCard } from './BalanceSheetCard';
import { BudgetCard } from './BudgetCard';
import { RecentTransactionsCard } from './RecentTransactionsCard';
import { TodayCard } from './TodayCard';

export const HomeContainer = (): ReactElement => {
  return (
    <Page>
      <PageHeader title="Home" />
      <div className="flex flex-col md:flex-row">
        <div className="mb-4 md:mb-0 md:mr-8">
          <TodayCard />
          <BudgetCard />
        </div>
        <div className="flex-auto">
          <RecentTransactionsCard />
          <BalanceSheetCard />
        </div>
      </div>
    </Page>
  );
};
