import { ReactElement } from 'react';
import { useMetaContext } from '../context/MetaContext';
import { Currency } from '../contracts';

export function Money({
  value,
  currency = Currency.GBP,
  className,
}: {
  value: number;
  currency?: Currency;
  className?: string;
}): ReactElement {
  return (
    <span className={className}>
      {(value / 100).toLocaleString('en-GB', { currency, style: 'currency' })}
    </span>
  );
}

Money.displayName = 'Money';
Money.defaultProps = {
  currency: Currency.GBP,
  className: undefined,
};

type UseConverterReturns = (
  foreign: Currency,
  local: Currency,
  value: number
) => number;

export const useConverter = (): UseConverterReturns => {
  const { forexRates } = useMetaContext();
  return (foreign: Currency, local: Currency, value = 1) => {
    const result = forexRates[foreign][local] * value;
    return result;
  };
};
