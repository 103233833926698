import { ReactElement } from 'react';
import {
  TransactionParams,
  UseMatchReturns,
  useTransactions,
} from '../api/transactions';
import { Button } from '../components/Button';
import { Modal, ModalProps } from '../components/modal/Modal';
import { Money } from '../components/Money';
import { AccountLabel } from '../components/AccountLabel';
import { Table, timeRenderer } from '../components/Table';
import { Card } from '../components/Card';
import { Transaction } from '../contracts';

interface MatchTransactionModalProps extends Omit<ModalProps, 'title' | 'id'> {
  editing?: Transaction;
  matchTransaction: UseMatchReturns;
}

export const MatchTransactionModal = ({
  closeModal,
  editing: transaction,
  matchTransaction,
  ...rest
}: MatchTransactionModalProps): ReactElement => {
  const params: TransactionParams = {
    matches: transaction?.id,
    unposted: true,
  };
  const potentialMatches = useTransactions(params, false);

  const onSubmit = (matchingId: string) => async () => {
    if (transaction) {
      await matchTransaction.mutateAsync({
        id: transaction?.id,
        matchCandidate: matchingId,
      });
      closeModal();
    }
  };

  return (
    <Modal
      title="Match Transaction"
      className="w-48"
      closeModal={closeModal}
      {...rest}
    >
      <Card className="mb-4 flex flex-row justify-between">
        <div>{transaction?.description}</div>
        <div>
          {transaction && (
            <Money currency={transaction.currency} value={transaction.value} />
          )}
        </div>
      </Card>
      <Table
        cellClassName="h-16"
        columns={[
          { id: 'timestamp', render: timeRenderer('timestamp') },
          { id: 'description', selectKey: 'description' },
          {
            id: 'account',
            render: ({ account }: Transaction) => (
              <AccountLabel accountId={account} />
            ),
          },
          {
            id: 'value',
            className: 'justify-end',
            render: ({ value, currency }: Transaction) => (
              <Money value={value} currency={currency} />
            ),
          },
          {
            id: 'match',
            label: '',
            className: 'justify-end pr-2 flex relative',
            render: ({ id }: Transaction) => (
              <Button
                onClick={onSubmit(id)}
                disabled={matchTransaction.isLoading}
              >
                Select
              </Button>
            ),
          },
        ]}
        data={(potentialMatches.data || []).filter(
          (t) => t.id !== transaction?.id
        )}
      />
    </Modal>
  );
};

MatchTransactionModal.defaultProps = {
  editing: undefined,
};
