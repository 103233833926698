import { ReactElement, useEffect } from 'react';
import { Trash } from 'react-feather';
import {
  useForm,
  Control,
  useWatch,
  useFieldArray,
  UseFormSetValue,
} from 'react-hook-form';
import { useCreateAccount } from '../api/accounts';
import { Input, SelectInput } from '../components/Input';
import { Button } from '../components/Button';
import { AccountCategory, accountCategoryToType, Currency } from '../contracts';
import { buildTruelayerURL } from '../truelayer';
import { Modal, ModalProps } from '../components/modal/Modal';
import { AutocompleteSelect } from '../components/AutocompleteSelect';
import { getStocks } from '../api/meta';
import { useAuthContext } from '../context/AuthContext';

interface Holding {
  ticker: string;
  volume: string;
  timestamp?: Date;
  price?: string;
}

interface FormData {
  name: string;
  currency: Currency;
  balance: string;
  category: AccountCategory;
  isISA: boolean;
  openedAt: Date;
  holdings: Holding[];
}

const redirectURL = buildTruelayerURL();

const currencies = [
  { value: Currency.GBP, label: 'Great British Pound (GBP)' },
  { value: Currency.AUD, label: 'Australian Dollar (AUD)' },
  { value: Currency.EUR, label: 'Euro (EUR)' },
  { value: Currency.USD, label: 'US Dollar (USD)' },
];

const categories: Array<{ value: AccountCategory; label: string }> = [
  { value: AccountCategory.SAVINGS, label: 'Bank Account' },
  { value: AccountCategory.INVESTMENT, label: 'Investments' },
  { value: AccountCategory.CRYPTO, label: 'Crypto' },
  { value: AccountCategory.PROPERTY, label: 'Property' },
  { value: AccountCategory.PENSION, label: 'Pension' },
  { value: AccountCategory.STUDENT_LOAN, label: 'Student Loan' },
  { value: AccountCategory.OTHER_LOAN, label: 'Other Loans' },
  { value: AccountCategory.CARD, label: 'Credit Card' },
];

export const PropertyForm = ({
  control,
}: {
  control: Control<FormData>;
}): ReactElement => {
  const category = useWatch({
    control,
    name: 'category',
  });
  if (category !== AccountCategory.PROPERTY) {
    return <></>;
  }
  return (
    <>
      <Input
        label="Address"
        name="name"
        autoComplete="off"
        control={control}
        rules={{ required: true }}
      />
      <SelectInput
        label="Currency"
        name="currency"
        control={control}
        rules={{ required: true }}
        isClearable
        options={currencies}
      />
      <Input
        label="Purchase price"
        type="number"
        name="balance"
        control={control}
        rules={{ required: true }}
      />
      <Input
        type="date"
        label="Purchase date"
        name="opening"
        control={control}
        rules={{ required: true }}
      />
    </>
  );
};

export const BankAccountForm = ({
  control,
}: {
  control: Control<FormData>;
}): ReactElement => {
  const category = useWatch({
    control,
    name: 'category',
  });
  if (category !== AccountCategory.SAVINGS) {
    return <></>;
  }
  return (
    <>
      <Input
        label="Account name"
        name="name"
        autoComplete="off"
        control={control}
        rules={{ required: true }}
      />
      <SelectInput
        label="Currency"
        name="currency"
        control={control}
        rules={{ required: true }}
        isClearable
        options={currencies}
      />
      <Input
        label="Account balance"
        type="number"
        name="balance"
        control={control}
        rules={{ required: true }}
      />
    </>
  );
};

export const InvestmentAccountForm = ({
  control,
  setValue,
}: {
  control: Control<FormData>;
  setValue: UseFormSetValue<FormData>;
}): ReactElement => {
  const category = useWatch({
    control,
    name: 'category',
  });

  const { fields, append, remove } = useFieldArray<FormData>({
    control,
    name: 'holdings',
  });

  const { getAccessToken } = useAuthContext();

  useEffect(() => {
    if (category !== AccountCategory.INVESTMENT) {
      setValue('holdings', []);
    }
  }, [category]);

  if (category !== AccountCategory.INVESTMENT) {
    return <></>;
  }
  return (
    <>
      <Input
        label="Account name"
        name="name"
        autoComplete="off"
        control={control}
        rules={{ required: true }}
      />
      <SelectInput
        label="Currency"
        name="currency"
        control={control}
        rules={{ required: true }}
        isClearable
        options={currencies}
      />
      <Input
        label="Cash balance"
        type="number"
        name="balance"
        control={control}
      />
      <hr className="mb-4" />
      {fields.map((field, index) => (
        <div key={field.id} className="flex items-center w-full">
          <AutocompleteSelect<string>
            label="Symbol"
            loadOptions={async (value: string) => {
              try {
                const accessToken = await getAccessToken();
                const results = await getStocks(value, accessToken);
                const tickers = results.map((result) => ({
                  label: result.ticker,
                  value: result.ticker,
                }));
                return tickers;
              } catch (e) {
                return [];
              }
            }}
            className="flex-1"
            control={control}
            rules={{ required: true }}
            name={`holdings.${index}.ticker`}
            showErrors={false}
          />
          <Input
            className="ml-2 w-36"
            type="number"
            label="Volume"
            rules={{ required: true }}
            control={control}
            name={`holdings.${index}.volume`}
            showErrors={false}
          />
          <Input
            className="ml-2"
            label="Purchase date"
            type="date"
            control={control}
            name={`holdings.${index}.timestamp`}
            showErrors={false}
          />
          <Input
            className="ml-2 w-36"
            label="Purchase price"
            type="number"
            min={1}
            step="any"
            control={control}
            name={`holdings.${index}.price`}
            showErrors={false}
          />
          <button
            type="button"
            onClick={() => remove(index)}
            className="mt-1 ml-2"
          >
            <Trash color="gray" height="16" />
          </button>
        </div>
      ))}
      <div className="flex flex-col p-4 justify-center items-center">
        <Button onClick={() => append({ ticker: '', volume: '' })}>
          Add Holding
        </Button>
        <p className="text-xs text-gray-500 block mt-4">
          If you bought at different prices add a holding for each price point
        </p>
      </div>
      <hr className="mt-4 mb-2" />
    </>
  );
};

export const AddAccountModal = ({
  closeModal,
  ...rest
}: Omit<ModalProps, 'id'>): ReactElement => {
  const { control, handleSubmit, reset, formState, setValue } =
    useForm<FormData>({
      mode: 'onChange',
      defaultValues: {
        name: '',
        currency: Currency.GBP,
        balance: '',
        holdings: [{ ticker: '', volume: '', price: '' }],
      },
    });

  const createAccount = useCreateAccount();

  const close = () => {
    closeModal();
  };

  const afterModalClose = () => {
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const accountType = accountCategoryToType(data.category);
    const trades = data.holdings.map((holding) => ({
      volume: parseFloat(holding.volume),
      ticker: holding.ticker,
      timestamp: holding.timestamp
        ? holding.timestamp.toISOString()
        : undefined,
      cost_basis: holding.price ? parseFloat(holding.price) : undefined,
    }));
    await createAccount.mutateAsync({
      accountType,
      ...data,
      trades,
      balance: Math.trunc(parseInt(data.balance, 10) * 100),
    });
    close();
  };

  return (
    <Modal onModalClosed={afterModalClose} closeModal={close} {...rest}>
      <div className="flex justify-center mb-4">
        <a href={redirectURL}>
          <Button type="button">Connect via Open Banking</Button>
        </a>
      </div>
      <hr />
      <div className="mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="category"
            label="Account category"
            control={control}
            rules={{ required: true }}
            isClearable
            options={categories}
          />
          <BankAccountForm control={control} />
          <InvestmentAccountForm control={control} setValue={setValue} />
          <PropertyForm control={control} />
          <Button
            className="mt-4 float-right"
            type="submit"
            disabled={createAccount.isLoading || !formState.isValid}
          >
            Add Account
          </Button>
        </form>
      </div>
    </Modal>
  );
};
