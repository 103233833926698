import { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { AuthPage } from '../components/AuthPage';
import { Button } from '../components/Button';
import { useAuthContext } from '../context/AuthContext';
import { useQuery } from '../location';

export const LoginContainer = (): ReactElement => {
  const params = useQuery();
  const { isSignedIn, login } = useAuthContext();
  if (isSignedIn) {
    const next = params.get('next');
    const to = next ? decodeURIComponent(next) : '/';
    return <Navigate to={to} />;
  }
  return (
    <AuthPage>
      <h1 className="text-2xl font-bold mb-10 text-center">Equity</h1>
      <Button className="w-full" onClick={() => login()} type="button">
        Login with Google
      </Button>
    </AuthPage>
  );
};
