import qs from 'qs';
import { components } from './schema';

export type Schemas = components['schemas'];
export interface ApiResponse<T, M = unknown> {
  data: T;
  meta: M;
}

const API_URL =
  import.meta.env.NODE_ENV === 'production' ||
  import.meta.env.VITE_USE_ENV === 'true'
    ? import.meta.env.VITE_API_URL || 'https://equity.fly.dev'
    : 'http://localhost:8082';

export const fetcher = async <T, B = unknown>(
  url: string,
  method = 'get',
  body: B | undefined = undefined,
  headers = {},
  params = {}
): Promise<T> => {
  const controller = new AbortController();
  try {
    const encodedParams = qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: 'repeat',
    });
    const res = await fetch(`${API_URL}${url}?${encodedParams}`, {
      method: method.toUpperCase(),
      signal: controller.signal,
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        ...headers,
      },
    });
    if (!res.ok) {
      if (import.meta.env.NODE_ENV !== 'production') {
        console.log(await res.text()); // eslint-disable-line
      }
      throw Error('Failed to load');
    }
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await res.json();
    }
    return {} as T;
  } finally {
    controller.abort();
  }
};
