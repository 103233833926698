import { Fragment, ReactElement } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from 'react-feather';
import clsx from 'clsx';

export interface ModalProps {
  title?: string;
  className?: string;
  isModalOpen: boolean;
  closeModal: () => void;
  onModalClosed?: () => void;
}

const ModalContainer = ({
  children,
  title,
  closeModal,
  isModalOpen,
  onModalClosed,
  className,
}: React.PropsWithChildren<ModalProps>): ReactElement => {
  return (
    <Transition
      appear
      show={isModalOpen}
      as={Fragment}
      afterLeave={onModalClosed}
    >
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="text-center min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0">
              <div className="absolute inset-0 opacity-50 bg-gray-500" />
            </Dialog.Overlay>
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                className,
                'inline-block w-full max-w-4xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded'
              )}
            >
              <div className="flex justify-between w-full mb-6">
                <Dialog.Title>{title}</Dialog.Title>
                <X
                  tabIndex={0}
                  className="outline-none"
                  onClick={closeModal}
                  role="button"
                />
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Modal = ({
  title,
  closeModal,
  children,
  isModalOpen,
  onModalClosed,
  className,
}: React.PropsWithChildren<ModalProps>): ReactElement | null => {
  return (
    <ModalContainer
      title={title}
      closeModal={closeModal}
      isModalOpen={isModalOpen}
      onModalClosed={onModalClosed}
      className={className}
    >
      {children}
    </ModalContainer>
  );
};
