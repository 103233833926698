import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateAccount } from '../api/accounts';
import { Input, SelectInput } from '../components/Input';
import { Button } from '../components/Button';
import { Modal, ModalProps } from '../components/modal/Modal';
import { IncomeAccountTypes } from '../contracts';
import { useProfileContext } from '../context/ProfileContext';

interface FormData {
  name: string;
  budget?: number;
  category: IncomeAccountType;
}

interface IncomeAccountType {
  value: IncomeAccountTypes;
  label: string;
}

const types: Array<IncomeAccountType> = [
  { value: 'EXPENSE', label: 'Spending' },
  { value: 'REVENUE', label: 'Income' },
];

type AddCategoryModalProps = Omit<ModalProps, 'title' | 'id'>;

export const AddCategoryModal = ({
  closeModal,
  ...rest
}: AddCategoryModalProps): ReactElement => {
  const {
    profile: { currency },
    getCurrencySymbol,
  } = useProfileContext();
  const { control, handleSubmit, formState, reset } = useForm<FormData>({
    defaultValues: {
      category: { value: 'EXPENSE', label: 'Spending' },
      name: '',
      budget: 0,
    },
  });
  const { errors } = formState;
  const createCategory = useCreateAccount();
  const onSubmit = async (data: FormData) => {
    const { category: accountType, budget, ...formRest } = data;
    await createCategory.mutateAsync({
      accountType: accountType.value,
      budget: budget ? budget * 100 : 0,
      ...formRest,
    });
    closeModal();
  };

  const onModalClosed = () => {
    reset();
  };

  return (
    <Modal
      title="Create new category"
      closeModal={closeModal}
      onModalClosed={onModalClosed}
      {...rest}
    >
      <small className="mb-4 w-full text-center block">
        Categories are used to categorise your purchases and other transactions.
      </small>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="input"
          label="Name"
          name="name"
          control={control}
          rules={{ required: true }}
          className="mb-2"
        />
        {errors.name && <span>This field is required</span>}
        <Input
          type="number"
          inputClassName="pl-7 pr-12"
          before={getCurrencySymbol(currency)}
          label="Monthly budget"
          after={currency.toUpperCase()}
          name="budget"
          control={control}
          className="mb-2"
        />
        {errors.name && <span>This field is required</span>}
        <SelectInput
          name="category"
          label="Spending or Income"
          control={control}
          rules={{ required: true }}
          isClearable
          options={types}
          className="mb-2"
          placeholder="Type"
        />
        <Button
          className="float-right mt-4"
          type="submit"
          disabled={createCategory.isLoading}
        >
          Add Category
        </Button>
      </form>
    </Modal>
  );
};
