import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CreateExportModal } from '../containers/CreateExportModal';
import { useAuthContext } from '../context/AuthContext';
import { useModal } from '../hooks/modal';

export const Navbar = (): ReactElement => {
  const { isSignedIn } = useAuthContext();
  const exportsModal = useModal();

  return (
    <>
      <header className="border-b w-full flex justify-center p-4 shadow-sm">
        <div className="max-w-screen-xl w-full flex flex-row justify-between">
          <div>
            {isSignedIn && (
              <ul className="flex flex-row">
                <li className="mr-4">
                  <Link to="/">Home</Link>
                </li>
                <li className="mr-4">
                  <Link to="/transactions">Transactions</Link>
                </li>
                <li className="mr-4">
                  <Link to="/accounts">Accounts</Link>
                </li>
                <li className="mr-4">
                  <Link to="/categories">Categories</Link>
                </li>
                <li className="mr-4">
                  <span
                    tabIndex={0}
                    role="link"
                    className="cursor-pointer"
                    onClick={() => exportsModal.openModal()}
                    onKeyDown={() => exportsModal.openModal()}
                  >
                    Export
                  </span>
                </li>
              </ul>
            )}
          </div>
          <nav>
            {isSignedIn ? (
              <Link to="/logout">Logout</Link>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
        </div>
      </header>
      <CreateExportModal
        isModalOpen={exportsModal.isModalOpen}
        closeModal={exportsModal.closeModal}
      />
    </>
  );
};
