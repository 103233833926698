import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { Modal, ModalProps } from '../components/modal/Modal';
import { useAuthedFetch } from '../api/useAuthedFetch';

interface FormData {
  begin: Date;
  end: Date;
}

type CreateExportModalProps = Omit<ModalProps, 'title' | 'id'>;

export const CreateExportModal = ({
  closeModal,
  ...rest
}: CreateExportModalProps): ReactElement => {
  const { control, handleSubmit, formState, reset } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const authedFetch = useAuthedFetch();
  const { errors } = formState;
  const onSubmit = async (data: FormData) => {
    const { begin, end } = data;
    setLoading(true);
    try {
      await authedFetch(`/export`, 'POST', {
        begin,
        end,
      });
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const onModalClosed = () => {
    reset();
  };

  return (
    <Modal
      title="Export transactions"
      closeModal={closeModal}
      onModalClosed={onModalClosed}
      {...rest}
    >
      <span className="block text-sm text-center m-2">
        Your export will be emailed to you.
      </span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="date"
          label="From"
          name="begin"
          control={control}
          rules={{ required: true }}
          className="mb-2"
        />
        {errors.begin && <span>This field is required</span>}
        <Input
          type="date"
          label="To"
          name="end"
          control={control}
          rules={{ required: true }}
          className="mb-2"
        />
        {errors.end && <span>This field is required</span>}
        <Button className="float-right mt-4" type="submit" disabled={loading}>
          Request Export
        </Button>
      </form>
    </Modal>
  );
};
