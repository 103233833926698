import React, { useContext, ReactElement } from 'react';
import { Spinner } from '../components/Spinner';
import { ForexRates } from '../contracts';
import { useForexRates } from '../api/meta';

export interface MetaContextProps {
  forexRates: ForexRates;
}

export const MetaContext = React.createContext<MetaContextProps | undefined>(
  undefined
);

export function useMetaContext(): MetaContextProps {
  const context = useContext(MetaContext);
  if (!context) throw new Error('MetaContext not initialized');
  return context;
}

/**
 * Allows rendering of content outside of the current components tree
 */
export function MetaContextProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const forexRates = useForexRates();

  if (!forexRates.data) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <MetaContext.Provider value={{ forexRates: forexRates.data }}>
      {children}
    </MetaContext.Provider>
  );
}
