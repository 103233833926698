import { ReactElement } from 'react';
import { Card, CardHeader, CardPlaceholder } from '../../components/Card';
import { useAccountsFiltered } from '../../api/accounts';
import { Money } from '../../components/Money';
import { AccountCategory, Currency } from '../../contracts';
import { useProfileContext } from '../../context/ProfileContext';

export const BalanceSheetCard = (): ReactElement => {
  const {
    profile: { currency },
  } = useProfileContext();

  const transactionAccounts = useAccountsFiltered(
    (account) => account.category === AccountCategory.TRANSACTION,
    currency
  );
  const savingAccounts = useAccountsFiltered(
    (account) => account.category === AccountCategory.SAVINGS,
    currency
  );
  const investmentAccounts = useAccountsFiltered(
    (account) =>
      account.category === AccountCategory.INVESTMENT ||
      account.category === AccountCategory.PENSION
  );
  const otherAssetAccounts = useAccountsFiltered(
    (account) =>
      ![
        AccountCategory.TRANSACTION,
        AccountCategory.SAVINGS,
        AccountCategory.INVESTMENT,
        AccountCategory.PENSION,
      ].includes(account.category) && account.accountType === 'ASSET'
  );
  const assetTotal =
    transactionAccounts.total +
    investmentAccounts.total +
    otherAssetAccounts.total +
    savingAccounts.total;

  const cardAccounts = useAccountsFiltered(
    (account) => account.category === AccountCategory.CARD,
    currency
  );
  const otherLiabilityAccounts = useAccountsFiltered(
    (account) =>
      account.category !== AccountCategory.CARD &&
      account.accountType === 'LIABILITY',
    currency
  );
  const liabilitiesTotal = cardAccounts.total + otherLiabilityAccounts.total;
  const netWorth = assetTotal - liabilitiesTotal;

  const isLoading =
    transactionAccounts.isLoading ||
    savingAccounts.isLoading ||
    investmentAccounts.isLoading ||
    otherAssetAccounts.isLoading ||
    cardAccounts.isLoading;

  return (
    <Card>
      <CardHeader title="Balance Sheet" />
      {isLoading && <CardPlaceholder count={10} />}
      {!isLoading && (
        <table className="w-full text-sm">
          <tbody>
            <tr>
              <td className="font-semibold">NET WORTH</td>
              <td className="text-right font-semibold">
                <Money value={netWorth} currency={Currency.GBP} />
              </td>
            </tr>
            <tr>
              <td className="font-semibold">Assets</td>
              <td className="text-right font-semibold">
                <Money value={assetTotal} currency={Currency.GBP} />
              </td>
            </tr>
            <tr>
              <td className="pl-2 font-semibold">Transaction Accounts</td>
              <td className="text-right font-semibold">
                <Money
                  value={transactionAccounts.total}
                  currency={Currency.GBP}
                />
              </td>
            </tr>
            {transactionAccounts.data.map((account) => (
              <tr key={account.id}>
                <td className="pl-4">{account.alias || account.name}</td>
                <td className="pl-4 text-right font-mono">
                  <Money currency={account.currency} value={account.balance} />
                </td>
              </tr>
            ))}
            <tr>
              <td className="pl-2 font-semibold">Saving Accounts</td>
              <td className="text-right font-semibold">
                <Money value={savingAccounts.total} currency={Currency.GBP} />
              </td>
            </tr>
            {savingAccounts.data.map((account) => (
              <tr key={account.id}>
                <td className="pl-4">{account.alias || account.name}</td>
                <td className="pl-4 text-right font-mono">
                  <Money currency={account.currency} value={account.balance} />
                </td>
              </tr>
            ))}
            <tr>
              <td className="pl-2 font-semibold">Investments</td>
              <td className="text-right font-semibold">
                <Money
                  value={investmentAccounts.total}
                  currency={Currency.GBP}
                />
              </td>
            </tr>
            {investmentAccounts.data.map((account) => (
              <tr key={account.id}>
                <td className="pl-4">{account.alias || account.name}</td>
                <td className="pl-4 text-right font-mono">
                  <Money currency={account.currency} value={account.balance} />
                </td>
              </tr>
            ))}
            <tr>
              <td className="pl-2 font-semibold">Other</td>
              <td className="text-right font-semibold">
                <Money
                  value={otherAssetAccounts.total}
                  currency={Currency.GBP}
                />
              </td>
            </tr>
            {otherAssetAccounts.data.map((account) => (
              <tr key={account.id}>
                <td>{account.alias || account.name}</td>
                <td>{account.balance}</td>
              </tr>
            ))}
            <tr>
              <td className="font-semibold">Liabilities</td>
              <td className="text-right font-semibold">
                <Money value={liabilitiesTotal} currency={Currency.GBP} />
              </td>
            </tr>
            <tr>
              <td className="pl-2 font-semibold">Credit Cards</td>
              <td className="text-right font-semibold">
                <Money value={cardAccounts.total} currency={Currency.GBP} />
              </td>
            </tr>
            {cardAccounts.data.map((account) => (
              <tr key={account.id}>
                <td className="pl-4">{account.alias || account.name}</td>
                <td className="pl-4 text-right font-mono">
                  <Money currency={account.currency} value={account.balance} />
                </td>
              </tr>
            ))}
            <tr>
              <td className="pl-2 font-semibold">Other Liabilities</td>
              <td className="text-right font-semibold">
                <Money
                  value={otherLiabilityAccounts.total}
                  currency={Currency.GBP}
                />
              </td>
            </tr>
            {otherLiabilityAccounts.data.map((account) => (
              <tr key={account.id}>
                <td className="pl-4">{account.alias || account.name}</td>
                <td className="pl-4 text-right font-mono">
                  <Money currency={account.currency} value={account.balance} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Card>
  );
};
