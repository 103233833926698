import React, { ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useNavigationType,
} from 'react-router';
import { BrowserRouter, Route, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DashboardContainer } from './containers/DashboardContainer';
import { LoginContainer } from './containers/LoginContainer';
import { OnboardingContainer } from './containers/OnboardingContainer';
import { AuthContextProvider, useAuthContext } from './context/AuthContext';
import { LogoutContainer } from './containers/LogoutContainer';
import { AcceptInviteContainer } from './containers/AcceptInviteContainer';
import { ProfileContextProvider } from './context/ProfileContext';
import { MetaContextProvider } from './context/MetaContext';
import { BrowserTracing } from '@sentry/react';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://b48bec1d2a554d37935aed06d30d42cf@o873854.ingest.sentry.io/5825220',
    environment: import.meta.env.VITE_ENVIRONMENT || 'local',
    release: import.meta.env.VITE_COMMIT_REF,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const SignedInRoutes = () => {
  return (
    <MetaContextProvider>
      <ProfileContextProvider>
        <SentryRoutes>
          <Route path="/login" element={<LoginContainer />} />
          <Route path="/logout" element={<LogoutContainer />} />
          <Route path="/join" element={<OnboardingContainer />} />
          <Route path="/accept" element={<AcceptInviteContainer />} />
          <Route path="/*" element={<DashboardContainer />} />
        </SentryRoutes>
      </ProfileContextProvider>
    </MetaContextProvider>
  );
};

const SignedOutRoutes = () => {
  const location = useLocation();
  return (
    <SentryRoutes>
      <Route path="/login" element={<LoginContainer />} />
      <Route
        path="/*"
        element={
          <Navigate
            to={{
              pathname: '/login',
              search:
                location.pathname !== '/logout'
                  ? `?next=${encodeURIComponent(
                      location.pathname + location.search
                    )}`
                  : '',
            }}
          />
        }
      />
    </SentryRoutes>
  );
};

const AuthRoutes = () => {
  const { isSignedIn } = useAuthContext();
  return isSignedIn ? <SignedInRoutes /> : <SignedOutRoutes />;
};

const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <AuthRoutes />
        </QueryClientProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export default App;
