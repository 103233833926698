import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
  InfiniteData,
} from 'react-query';
import { ApiResponse, fetcher, Schemas } from './index';
import { Transaction, Currency } from '../contracts';
import { useAuthContext } from '../context/AuthContext';
import ApiError from './ApiError';

interface Created {
  gte?: number;
  gt?: number;
  lte?: number;
  lt?: number;
}

export interface TransactionParams {
  unposted?: boolean;
  accountId?: string;
  created?: Created;
  limit?: number;
  matches?: string;
  reverse?: boolean;
}

export const transactionsConverter = (
  transaction: Schemas['Transaction'],
  extras: Partial<Transaction> = {}
): Transaction => ({
  id: transaction.id,
  entries: transaction.entries.map((entry) => ({
    value: entry.value,
    accountId: entry.account_id,
  })),
  account: transaction.account_id,
  description: transaction.description,
  currency: transaction.currency as Currency,
  value: transaction.value,
  timestamp: new Date(Date.parse(transaction.timestamp)),
  merchants: transaction.merchants,
  ...extras,
});

export const useTransactions = (
  params: TransactionParams,
  keepPreviousData = true,
  key = 'transactions'
): UseQueryResult<Array<Transaction>> => {
  const { getAccessToken } = useAuthContext();
  return useQuery<
    ApiResponse<Array<Schemas['Transaction']>>,
    ApiError,
    Array<Transaction>
  >(
    [key, params],
    async () => {
      const accessToken = await getAccessToken();

      return fetcher(
        '/transactions',
        'GET',
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { limit: params.limit || 50, ...params }
      );
    },
    {
      keepPreviousData,
      select: (result) => result.data.map((v) => transactionsConverter(v)),
    }
  );
};

export const useInfiniteTransactions = (
  params: TransactionParams
): UseInfiniteQueryResult<ApiResponse<Array<Schemas['Transaction']>>> => {
  const { getAccessToken } = useAuthContext();
  return useInfiniteQuery<
    ApiResponse<Array<Schemas['Transaction']>, Schemas['ListMeta']>,
    ApiError,
    ApiResponse<Array<Schemas['Transaction']>, Schemas['ListMeta']>
  >(
    ['transactions', params],
    async ({ pageParam = undefined }) => {
      const accessToken = await getAccessToken();
      return fetcher(
        '/transactions',
        'GET',
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { limit: params.limit || 50, cursor: pageParam, ...params }
      );
    },
    {
      keepPreviousData: true,
      getNextPageParam: (page) => page.meta.next_cursor,
    }
  );
};

export type UsePostReturns = UseMutationResult<
  Schemas['Transaction'],
  Error,
  { id: string; destinations: Array<Schemas['PostDestination']> }
>;
export const usePostTransaction = (
  params: TransactionParams
): UsePostReturns => {
  const queryClient = useQueryClient();
  const { getAccessToken } = useAuthContext();

  return useMutation(
    async (updated: {
      id: string;
      destinations: Array<Schemas['PostDestination']>;
    }) => {
      const { id, destinations } = updated;
      const accessToken = await getAccessToken();
      return fetcher<Schemas['Transaction'], Array<Schemas['PostDestination']>>(
        `/transactions/${id}/post`,
        'POST',
        destinations,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onSuccess: async (data) => {
        const key = ['transactions', params];
        await queryClient.cancelQueries(key);
        const current = queryClient.getQueryData(key) as InfiniteData<
          ApiResponse<Array<Schemas['Transaction']>>
        >;
        const updated = current.pages.map((page) => ({
          ...page,
          data: page.data.flatMap((val) => {
            if (val.id === data.id) {
              if (params.unposted === false) {
                return [];
              }
              return [data];
            }
            return [val];
          }),
        }));
        // debugger; // eslint-disable-line
        queryClient.setQueryData(key, { ...current, pages: updated });
        return current;
      },
      onError: (error, variables, context) => {
        const key = ['transactions', params];
        queryClient.setQueryData(key, context);
      },
    }
  );
};

export type UseResetTransactionReturns = UseMutationResult<
  ApiResponse<Schemas['Transaction']>,
  Error,
  { id: string }
>;

export const useResetTransaction = (
  params: TransactionParams
): UseResetTransactionReturns => {
  const queryClient = useQueryClient();
  const { getAccessToken } = useAuthContext();

  return useMutation(
    async (updated: { id: string }) => {
      const { id } = updated;
      const accessToken = await getAccessToken();
      return fetcher<ApiResponse<Schemas['Transaction']>, void>(
        `/transactions/${id}/reset`,
        'POST',
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onMutate: async (mutated) => {
        const key = ['transactions', params];
        await queryClient.cancelQueries(key);
        const current = queryClient.getQueryData(key) as InfiniteData<
          ApiResponse<Transaction[]>
        >;
        const updated = current.pages.map((page) => ({
          ...page,
          data: page.data.flatMap((val) => {
            if (val.id === mutated.id) {
              if (params.unposted === false) {
                return [];
              }
              return [{ ...val, entries: [] }];
            }
            return [val];
          }),
        }));
        // debugger; // eslint-disable-line
        queryClient.setQueryData(key, { ...current, pages: updated });
        return current;
      },
      onError: (error, variables, context) => {
        const key = ['transactions', params];
        queryClient.setQueryData(key, context);
      },
    }
  );
};

export interface MatchTransactionParams {
  id: string;
  matchCandidate: string;
}

export type UseMatchReturns = UseMutationResult<
  ApiResponse<Schemas['Transaction']>,
  Error,
  MatchTransactionParams
>;

export const useMatchTransaction = (
  params: TransactionParams
): UseMatchReturns => {
  const queryClient = useQueryClient();
  const { getAccessToken } = useAuthContext();

  return useMutation(
    async (updated: MatchTransactionParams) => {
      const accessToken = await getAccessToken();
      const { id, matchCandidate } = updated;
      return fetcher<
        ApiResponse<Schemas['Transaction']>,
        Schemas['MatchRequest']
      >(
        `/transactions/${id}/match`,
        'POST',
        { match_candidate: matchCandidate },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    {
      onMutate: async (mutated) => {
        const key = ['transactions', params];
        await queryClient.cancelQueries(key);
        const current = queryClient.getQueryData(key) as InfiniteData<
          ApiResponse<Transaction[]>
        >;
        const updated = current.pages.map((page) => ({
          ...page,
          data: page.data.flatMap((val) => {
            if (val.id === mutated.id || val.id === mutated.matchCandidate) {
              if (params.unposted) {
                return [];
              }
              return [{ ...val, ...mutated }];
            }
            return [val];
          }),
        }));
        // debugger; // eslint-disable-line
        queryClient.setQueryData(key, { ...current, pages: updated });
        return current;
      },
      onError: (error, variables, context) => {
        const key = ['transactions', params];
        queryClient.setQueryData(key, context);
      },
    }
  );
};
