import React, { ReactElement } from 'react';
import { Text } from './Text';

interface PageProps {
  children: JSX.Element | JSX.Element[];
}

export const Page = ({ children }: PageProps): ReactElement => (
  <div className="w-full ml-auto mr-auto max-w-screen-xl">{children}</div>
);

export const PageHeader = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>): ReactElement => (
  <div className="flex flex-row justify-between items-center w-full mb-5 mt-2">
    <Text variant="h1">{title}</Text>
    <div className="flex items-center">{children}</div>
  </div>
);
