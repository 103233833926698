import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateBudget } from '../api/budget';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { Modal, ModalProps } from '../components/modal/Modal';

interface FormData {
  budget: number;
}

interface EditCategoryBudgetModalProps
  extends Omit<ModalProps, 'title' | 'id'> {
  category: string;
  currentValue?: number;
}

export const EditCategoryBudgetModal = ({
  closeModal,
  category,
  currentValue,
  ...rest
}: EditCategoryBudgetModalProps): ReactElement => {
  const { reset, control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: { budget: currentValue },
  });
  const { errors } = formState;
  const createBudget = useCreateBudget();
  const onClose = () => {
    reset();
    closeModal();
  };
  const onSubmit = async (data: FormData) => {
    await createBudget.mutateAsync({
      value: data.budget * 100,
      account_id: category,
    });
    onClose();
  };

  return (
    <Modal title="Set category budget" closeModal={onClose} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="number"
          step="0.01"
          placeholder="Monthly budget"
          name="budget"
          control={control}
          className="mb-2"
        />
        {errors.budget && <span>This field is required</span>}
        <Button type="submit" disabled={createBudget.isLoading}>
          Set Budget
        </Button>
      </form>
    </Modal>
  );
};

EditCategoryBudgetModal.defaultProps = {
  currentValue: undefined,
};
