import React, { useContext, useMemo, useCallback, ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Spinner } from '../components/Spinner';
import { auth, provider } from '../firebase';
import { signInWithPopup, signOut } from 'firebase/auth';

export interface AuthContextProps {
  isSignedIn: boolean;
  loading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getAccessToken: () => Promise<string | undefined>;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

export function useAuthContext(): AuthContextProps {
  const context = useContext(AuthContext);
  if (!context) throw new Error('AuthContext not initialized');
  return context;
}

/**
 * Allows rendering of content outside of the current components tree
 */
export function AuthContextProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const [user, loading] = useAuthState(auth);

  const login = useCallback(async () => {
    await signInWithPopup(auth, provider);
  }, []);

  const logout = useCallback(async () => {
    await signOut(auth);
  }, []);

  const getAccessToken = useCallback(async (): Promise<string> => {
    const result = user?.getIdToken();
    if (!result) {
      throw Error('Unable to load accessToken');
    }
    return result;
  }, [user?.getIdToken]);

  const value = useMemo(
    () => ({
      isSignedIn: !!user,
      loading,
      user,
      login,
      getAccessToken,
      logout,
    }),
    [user, login, getAccessToken, loading, logout]
  );

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }
  if (user) {
    Sentry.setUser({ email: user.email || undefined, id: user.uid });
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
