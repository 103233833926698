import { ReactElement, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useQuery } from '../location';
import { useCreateFamily } from '../api/profile';
import { AuthPage } from '../components/AuthPage';
import { useProfileContext } from '../context/ProfileContext';

interface FormData {
  inviteCode: string;
}

export const AcceptInviteContainer = (): ReactElement => {
  const queryParams = useQuery();
  const inviteCode = queryParams.get('invite_code');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { profile } = useProfileContext();
  const createFamily = useCreateFamily();

  const { setError, register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: { inviteCode: inviteCode || '' },
  });
  const { errors } = formState;

  const joinFamily = async (data: FormData) => {
    setLoading(true);
    try {
      await createFamily.mutateAsync({ code: data.inviteCode });
    } catch {
      setError('inviteCode', {
        type: 'manual',
        message: 'Invalid invite code',
      });
    } finally {
      setLoading(false);
      setDone(true);
    }
  };

  useEffect(() => {
    if (inviteCode && inviteCode !== '' && !done && !loading) {
      joinFamily({ inviteCode });
    }
  }, [inviteCode, done]);

  if (profile.defaultFamilyId) {
    return <Navigate to="/" />;
  }

  return (
    <AuthPage>
      <h1 className="text-2xl font-bold mb-10 text-center">Accept Invite</h1>
      <form onSubmit={handleSubmit(joinFamily)}>
        <div className="mb-4">
          <Input placeholder="Your invite code" {...register('inviteCode')} />
          {errors.inviteCode && <span>{errors.inviteCode.message}</span>}
        </div>
        <Button type="submit" disabled={loading}>
          Accept Invite
        </Button>
      </form>
    </AuthPage>
  );
};
