import { ReactElement, memo, ComponentProps, PropsWithChildren } from 'react';
import { format, formatRelative } from 'date-fns';
import clsx from 'clsx';
import { Icon as FeatherIcon } from 'react-feather';
import { Spinner } from './Spinner';

export function TwoLineCell({
  children,
  src,
  alt,
  icon: Icon,
}: PropsWithChildren<{
  src?: string;
  alt?: string;
  icon?: FeatherIcon;
}>): ReactElement {
  return (
    <div className="flex pt-1 pb-1 overflow-ellipsis whitespace-nowrap overflow-hidden items-center">
      {src ? (
        <img src={src} alt={alt} className="w-4 h-4 mr-4 ml-4" />
      ) : Icon ? (
        <Icon className="w-4 h-4 mr-4 ml-4" />
      ) : (
        <div className="w-4 h-4 bg-gray-200 mr-4 ml-4 rounded-sm">&nbsp;</div>
      )}
      <div>{children}</div>
    </div>
  );
}

TwoLineCell.Row1 = ({ children }: PropsWithChildren<unknown>) => {
  return <div className="font-semibold">{children}</div>;
};

TwoLineCell.Row2 = ({ children }: PropsWithChildren<unknown>) => {
  return <div className="text-gray-400">{children}</div>;
};

TwoLineCell.defaultProps = {
  line2: undefined,
  src: undefined,
  alt: undefined,
  icon: undefined,
};

interface TableColumns<T> {
  select?: (item: T) => string;
  render?: (item: T) => ReactElement;
  id: string;
  label?: string;
  selectKey?: keyof T;
  width?: string;
  className?: string;
}

function TableHeader<T>({
  columns,
}: {
  columns: TableColumns<T>[];
}): ReactElement {
  return (
    <tr className="text-left">
      {columns.map((col) => (
        <th
          key={col.id}
          className={clsx(
            'sticky top-0 border-b border-gray-200 py-2 tracking-wider uppercase text-xs',
            {
              [`w-${col.width}`]: col.width,
            }
          )}
        >
          <div className={clsx('flex', col.className)}>
            {col.label !== undefined ? col.label : col.id}
          </div>
        </th>
      ))}
    </tr>
  );
}

function TableCellContents<T>({
  column,
  item,
}: {
  column: TableColumns<T>;
  item: T;
}) {
  if (column.render) {
    return column.render(item);
  }
  if (column.select) {
    return <span>{column.select(item)}</span>;
  }
  if (column.selectKey) {
    return <span>{item[column.selectKey] as string}</span>;
  }
  throw Error('selectKey, render or select not defined in column');
}

const typedMemo: <
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any> // eslint-disable-line
>(
  c: T,
  eq?: (a: ComponentProps<T>, b: ComponentProps<T>) => boolean
) => T = memo;

export function TableCell<T>({
  column,
  item,
  className,
}: {
  column: TableColumns<T>;
  item: T;
  className?: string;
}): ReactElement {
  return (
    <td
      className={clsx(
        'group-hover:bg-gray-100 pl-1 border-b border-gray-200',
        className
      )}
    >
      <div
        className={clsx(
          'text-gray-900 py-1 flex items-center text-sm',
          column.className
        )}
      >
        <TableCellContents column={column} item={item} />
      </div>
    </td>
  );
}

TableCell.defaultProps = {
  className: undefined,
};

function InternalTableRow<T>({
  columns,
  item,
  cellClassName,
}: {
  columns: TableColumns<T>[];
  item: T;
  cellClassName?: string;
}): ReactElement {
  return (
    <tr className="group">
      {columns.map((col) => (
        <TableCell
          className={cellClassName}
          key={col.id}
          column={col}
          item={item}
        />
      ))}
    </tr>
  );
}

InternalTableRow.defaultProps = {
  cellClassName: undefined,
};

const TableRow = typedMemo(InternalTableRow);

interface TableProps<T> {
  columns: TableColumns<T>[];
  data: T[];
  cellClassName?: string;
  loading?: boolean;
}

export function Table<T extends { id: string }>({
  columns,
  data,
  cellClassName,
  loading,
}: TableProps<T>): ReactElement {
  return (
    <div className="bg-white overflow-y-visible relative">
      <div
        className={clsx(
          'absolute w-full h-full z-10 flex justify-center items-center bg-white bg-opacity-50',
          {
            visible: loading,
            hidden: !loading,
          }
        )}
      >
        <Spinner />
      </div>
      <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
        <thead>
          <TableHeader columns={columns} />
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow
              key={item.id}
              columns={columns}
              item={item}
              cellClassName={cellClassName}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

Table.defaultProps = {
  cellClassName: undefined,
  loading: false,
};

export function timeRenderer<T>(
  key: keyof T,
  relative = false
): (item: T) => ReactElement {
  return (item: T) => {
    if (!item[key]) { // eslint-disable-line
      return <></>;
    }
    const formatted = relative ? formatRelative(item[key] as unknown as Date, new Date()) : format(item[key] as unknown as Date, 'd MMM yyyy HH:mm'); // eslint-disable-line
    return <span>{formatted}</span>;
  };
}
