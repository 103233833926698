import { useCallback, useState } from 'react';
import useEscape from './useEscape';

interface UseModalReturn {
  closeModal: () => void;
  isModalOpen: boolean;
  openModal: () => void;
}

export const useModal = (): UseModalReturn => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  useEscape(() => {
    if (isModalOpen) closeModal();
  });
  return { isModalOpen, closeModal, openModal };
};

interface UseEditModalReturn<T> extends Omit<UseModalReturn, 'openModal'> {
  editing: T | undefined;
  onEdit: (id: T) => void;
  onModalClosed: () => void;
}

export const useEditModal = <T>(initialValue?: T): UseEditModalReturn<T> => {
  const modal = useModal();
  const [editing, setEditing] = useState<T | undefined>(initialValue);
  const onEdit = (id: T) => {
    setEditing(id);
    modal.openModal();
  };

  const onEditClose = () => {
    modal.closeModal();
  };

  const onModalClosed = () => {
    setEditing(undefined);
  };

  return {
    isModalOpen: modal.isModalOpen,
    onEdit,
    onModalClosed,
    editing,
    closeModal: onEditClose,
  };
};
