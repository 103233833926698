import { ReactElement, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuthedFetch } from '../api/useAuthedFetch';
import { Spinner } from '../components/Spinner';
import { useQuery } from '../location';

export const ConnectContainer = (): ReactElement => {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const params = useQuery();
  const location = useLocation();
  const redirectURI = window.location.origin + location.pathname;
  const authFetch = useAuthedFetch();
  const code = params.get('code');

  const createConnection = async (
    source: string,
    authorizationCode: string
  ) => {
    try {
      await authFetch('/connections', 'POST', {
        source,
        authorization_code: authorizationCode,
        redirect_uri: redirectURI,
      });
      setComplete(true);
    } catch (e) {
      setError(JSON.stringify(e));
    }
  };

  useEffect(() => {
    if (!complete) {
      if (code) {
        createConnection('truelayer', code);
      } else {
        setComplete(true);
      }
    }
  }, [code, complete]);

  if (complete) {
    return <Navigate to="/accounts" />;
  }

  return (
    <div className="flex flex-1 flex-col justify-center items-center">
      <Spinner />
      <h2 className="mt-4">Connecting...</h2>
      {error}
    </div>
  );
};
