import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthedFetch } from '../api/useAuthedFetch';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useProfileContext } from '../context/ProfileContext';

interface FormData {
  email: string;
}

export const InviteContainer = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const authedFetch = useAuthedFetch();
  const { profile } = useProfileContext();

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await authedFetch(
        `/profile/families/${profile.defaultFamilyId}/invite`,
        'POST',
        {
          email: data.email,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        control={control}
        name="email"
        rules={{ required: true }}
      />
      {errors.email && <span>This field is required</span>}
      <div className="mt-3">
        <Button type="submit" disabled={loading}>
          Invite
        </Button>
      </div>
    </form>
  );
};
