import { Schemas } from './api';

export enum AccountCategory {
  SAVINGS = 'SAVINGS',
  TRANSACTION = 'TRANSACTION',
  INVESTMENT = 'INVESTMENT',
  PROPERTY = 'PROPERTY',
  CRYPTO = 'CRYPTO',
  MORTGAGE = 'MORTGAGE',
  OTHER_LOAN = 'OTHER_LOAN',
  CARD = 'CARD',
  STUDENT_LOAN = 'STUDENT_LOAN',
  PENSION = 'PENSION',
}

export const accountCategoryToType = (
  category: AccountCategory
): BalanceSheetAccountTypes | IncomeAccountTypes => {
  const mapping: Record<
    AccountCategory,
    BalanceSheetAccountTypes | IncomeAccountTypes
  > = {
    [AccountCategory.SAVINGS]: 'ASSET',
    [AccountCategory.TRANSACTION]: 'ASSET',
    [AccountCategory.INVESTMENT]: 'ASSET',
    [AccountCategory.PROPERTY]: 'ASSET',
    [AccountCategory.PENSION]: 'ASSET',
    [AccountCategory.CRYPTO]: 'ASSET',
    [AccountCategory.MORTGAGE]: 'LIABILITY',
    [AccountCategory.OTHER_LOAN]: 'LIABILITY',
    [AccountCategory.CARD]: 'LIABILITY',
    [AccountCategory.STUDENT_LOAN]: 'LIABILITY',
  };
  return mapping[category];
};

export enum Currency {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
  AUD = 'AUD',
}

export type ForexRates = Record<Currency, Record<Currency, number>>;

export type BalanceSheetAccountTypes = Schemas['BalanceSheetAccountTypes'];
export type IncomeAccountTypes = Schemas['IncomeAccountTypes'];

type ConnectionId = string;

export interface Connection {
  id: ConnectionId;
  logoUri?: string;
  provider: string;
  source: string;
}

export interface JournalEntry {
  value: number;
  accountId: AccountId;
}

export interface Transaction {
  id: string;
  value: number;
  timestamp: Date;
  currency: Currency;
  account: string;
  description: string;
  merchants: Array<string>;
  logo?: string;
  entries: Array<JournalEntry>;
  parent?: string;
  split?: [number, number];
}

export type AccountId = string;

export interface Account {
  id: AccountId;
  name: string;
  balance: number;
  category: AccountCategory;
  accountType: BalanceSheetAccountTypes | IncomeAccountTypes;
  currency: Currency;
  lastUpdate?: Date;
  alias?: string;
  connection: string;
}

export type CategoryId = string;

export interface Category {
  id: CategoryId;
  name: string;
}

export interface Budget {
  categoryId: string;
  value: number;
  current: number;
}

export interface Profile {
  id: string;
  email: string;
  defaultFamilyId?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  currency: Currency;
}

export interface Family {
  id: string;
}

export enum PostDestinationType {
  Category = 'CATEGORY',
  Account = 'ACCOUNT',
}

export interface Stock {
  ticker: string;
  exchange: string;
  name: string;
}
