import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Navbar } from '../components/Navbar';
import { AccountsContainer } from './AccountsContainer';
import { ConnectContainer } from './ConnectContainer';
import { InsightsContainer } from './InsightsContainer';
import { TransactionsContainer } from './TransactionsContainer';
import { InviteContainer } from './InviteContainer';
import { HomeContainer } from './Home';
import { useProfileContext } from '../context/ProfileContext';
import { CategorySettingsContainer } from './CategoriesContainer';
import { StylesContainer } from './StylesContainer';

export const DashboardContainer = (): ReactElement => {
  const { profile } = useProfileContext();
  if (!profile.defaultFamilyId) {
    return <Navigate to="/join" />;
  }
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex flex-1 justify-center p-4 w-full">
        <div className="w-full flex flex-1 max-w-screen-xxl">
          <Routes>
            <Route path="/transactions" element={<TransactionsContainer />} />
            <Route path="/categories" element={<CategorySettingsContainer />} />
            <Route path="/accounts" element={<AccountsContainer />} />
            <Route path="/styles" element={<StylesContainer />} />
            <Route path="/insights" element={<InsightsContainer />} />
            <Route path="/invite" element={<InviteContainer />} />
            <Route path="/callback" element={<ConnectContainer />} />
            <Route path="/" element={<HomeContainer />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
