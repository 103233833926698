import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Card, CardFooter, CardHeader } from '../../components/Card';
import { useAccounts } from '../../api/accounts';
import { useBudgets } from '../../api/budget';
import { Money } from '../../components/Money';
import { Button } from '../../components/Button';

interface BudgetRowProps {
  current: number;
  value: number;
  name: string;
}

export const BudgetRow = ({
  current,
  value,
  name,
}: BudgetRowProps): ReactElement => {
  const percent = Math.round((current / value) * 100);
  return (
    <div className="mb-8 text-sm">
      <div className="flex justify-between items-center mb-2">
        <span>{name}</span>
        <div>
          <small className="text-gray-400 mr-2">({percent}%)</small>
          <Money value={current} className="text-green-900" />
          <span className="pr-1 pl-1">/</span>
          <Money value={value} />
        </div>
      </div>
      <div className="w-full h-4 rounded bg-gray-200">
        <div
          className={clsx('h-full h-full rounded-sm', {
            'bg-green-900': percent <= 100,
            'bg-red-900': percent > 100,
          })}
          style={{ width: `${Math.min(percent, 100)}%` }}
        />
      </div>
    </div>
  );
};

export const BudgetCard = (): ReactElement => {
  const categories = useAccounts({ types: ['EXPENSE', 'REVENUE'] });
  const categoriesData = Object.values(categories.data || {});
  const budget = useBudgets();
  const hasBudget = budget.isLoading
    ? true
    : Object.keys(budget.data || {}).length !== 0;
  const categoriesByKey: Record<string, string> = useMemo(
    () =>
      (categoriesData || []).reduce((result, current) => {
        return { ...result, [current.id]: current.name };
      }, {}),
    [categories.data]
  );
  const budgetEntries = Object.entries(budget.data || {}).sort(
    ([, a], [, b]) => b.current / b.value - a.current / a.value
  );
  return (
    <Card className="mb-4 md:mb-8">
      <CardHeader title="Spending this month" />
      {!hasBudget && (
        <div className="w-full flex items-center flex-col mb-2">
          <div className="mb-4">Setup a budget to track your progress</div>
          <Link to="/categories">
            <Button>Setup now</Button>
          </Link>
        </div>
      )}
      {budgetEntries.map(([tagId, b]) => {
        return (
          <BudgetRow
            name={categoriesByKey[tagId]}
            value={b.value}
            key={tagId}
            current={b.current}
          />
        );
      })}
      <CardFooter to="/accounts" label="View budgets" />
    </Card>
  );
};
