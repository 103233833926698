import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { endOfMonth, startOfMonth } from 'date-fns';
import { ApiResponse, fetcher, Schemas } from './index';
import { useAuthContext } from '../context/AuthContext';
import ApiError from './ApiError';
import { Budget } from '../contracts';

export interface BudgetParams {
  start?: Date;
  end?: Date;
}

export const useBudgets = (
  params: BudgetParams = {}
): UseQueryResult<Record<string, Budget>> => {
  const { getAccessToken } = useAuthContext();
  const defaultParams = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
    ...params,
  };
  return useQuery<
    Array<Schemas['BudgetItem']>,
    ApiError,
    Record<string, Budget>
  >(
    ['budgets', defaultParams],
    async (): Promise<Array<Schemas['BudgetItem']>> => {
      const accessToken = await getAccessToken();

      return fetcher(
        '/budgets',
        'GET',
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        defaultParams
      );
    },
    {
      select: (budgets) =>
        budgets.reduce(
          (previous, budget) => ({
            ...previous,
            [budget.account_id]: {
              value: budget.value,
              current: budget.current,
            },
          }),
          {}
        ),
    }
  );
};

export const useCreateBudget = (): UseMutationResult<
  ApiResponse<Schemas['BudgetItem']>,
  Error,
  Schemas['CreateBudgetItem']
> => {
  const { getAccessToken } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Schemas['CreateBudgetItem']) => {
      const accessToken = await getAccessToken();
      return fetcher('/budgets', 'POST', params, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('budgets');
      },
    }
  );
};
