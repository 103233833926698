import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { ReactElement } from 'react';

export interface NetworthGraphProps {
  data: Record<string, number>;
  width?: number;
  height?: number;
}

const margin = {
  top: 40,
  bottom: 40,
  left: 40,
  right: 40,
};

export const NetworthGraph = ({
  data,
  width: parentWidth = 800,
  height: parentHeight = 800,
}: NetworthGraphProps): ReactElement => {
  const width = parentWidth - margin.left - margin.right;
  const height = parentHeight - margin.top - margin.bottom;

  const keys = Object.keys(data);
  const values = Object.values(data);

  const xScale = scaleBand<string>({
    range: [0, width],
    round: true,
    domain: keys,
    padding: 0.4,
  });
  const barWidth = xScale.bandwidth();
  const yScale = scaleLinear<number>({
    range: [height, 0],
    round: true,
    domain: [Math.max(...values), 0],
  });

  const bars = keys.map((x) => {
    const y = data[x];
    const barHeight = yScale(y);
    const barX = xScale(x);
    const barY = height - barHeight;
    return (
      <Bar
        key={`bar-${x}`}
        x={barX}
        y={barY}
        width={barWidth}
        height={barHeight}
      />
    );
  });

  return (
    <svg width={parentWidth} height={parentHeight}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>{bars}</g>
    </svg>
  );
};
